import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  Tooltip,
  ArcElement,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  SubTitle,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  SubTitle,
  Title
);

function ChartsPage(props) {
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    fetchStatistics();
  }, [props.filters]);

  const random_rgba = (opacity) => {
    var o = Math.round,
      r = Math.random,
      s = 255;
    return (
      "rgba(" +
      o(r() * s) +
      "," +
      o(r() * s) +
      "," +
      o(r() * s) +
      "," +
      r().toFixed(1) +
      ")"
    );
  };

  const fetchStatistics = () => {
    let url = "/rest/content/data.php?module=statistics&{filters}";

    console.log(props.filtersObj);

    url = url.replace("{filters}", props.filters);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setStatistics([...data.data]);
      })
      .catch((error) => console.error(error));
  };

  const createCharts = () => {
    let filters = props.filtersObj;

    let _charts = new Array();

    var hasfilter = false;

    if (filters) {
      Object.keys(filters).forEach((itm) => {
        if (filters[itm]) {
          hasfilter = true;
        }
      });
    }

    if (hasfilter) {
      if (filters.region) {
        let _regions = filters.region.split(",");

        if (_regions.length > 0) {
          var status_1 = [];

          var status_2 = [];

          var status_3 = [];

          var labels = [];

          var subsections = [];

          var subsectionsDatasets = {};

          var budget_status_1 = [];

          var budget_status_2 = [];

          var budget_status_3 = [];

          var budget = [];

          var section_1 = [];

          var section_2 = [];

          var section_3 = [];

          var section_4 = [];

          try {
            statistics.forEach((itm) => {
              if (
                itm.objecttype == "REGION" &&
                _regions.indexOf(itm.id.toString()) >= 0
              ) {
                status_1.push(itm.count_projects_status_design);
                status_2.push(itm.count_projects_status_pending);
                status_3.push(itm.count_projects_status_completed);

                section_1.push(itm.count_sectionid_1);
                section_2.push(itm.count_sectionid_2);
                section_3.push(itm.count_sectionid_3);
                section_4.push(itm.count_sectionid_4);

                budget.push(itm.sum_budget);

                budget_status_1.push(itm.budget_projects_status_design);
                budget_status_2.push(itm.budget_projects_status_pending);
                budget_status_3.push(itm.budget_projects_status_completed);

                if (window["category_labels"]) {
                  Object.keys(window["category_labels"]).map((cl) => {
                    if (typeof subsectionsDatasets[cl] === "undefined") {
                      subsectionsDatasets[cl] = [];
                    }
                    subsectionsDatasets[cl].push(
                      itm["count_subsectionid_".concat(cl)]
                    );
                  });
                }

                labels.push(itm.label);
              }
            });

            Object.keys(window["category_labels"]).map((cl) => {
              subsections.push({
                label: window["category_labels"][cl],
                data: subsectionsDatasets[cl],
                backgroundColor: window["random_colors"][cl],
                hidden: window["category_labels_hidden"]
                  ? window["category_labels_hidden"].indexOf(parseInt(cl)) >= 0
                    ? false
                    : true
                  : false,
              });
            });
          } catch (e) {
            console.log(e);
          }

          //per status count
          _charts.push({
            col: 6,
            chart: (
              <Bar
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text:
                        _regions.length == 1
                          ? "Συγκεντρωτικά της περιφέρειας ".concat(
                              labels[0],
                              " ανά κατάσταση"
                            )
                          : "Συγκεντρωτικά ανά περιφέρεια άνα κατάσταση",
                    },
                    subtitle: {
                      display: true,
                      text: window["translations"]?.["tooltips"]?.[
                        "chartSubtitle"
                      ],
                      padding: 12,
                      font: {
                        style: "italic",
                      },
                      position: "bottom",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Υπό σχεδιασμό",
                      data: status_1,
                      backgroundColor: "rgb(219, 210, 234)",
                    },
                    {
                      label: "Σε εξέλιξη",
                      data: status_2,
                      backgroundColor: "rgb(250, 215, 164)",
                    },
                    {
                      label: "Ολοκληρωμένα",
                      data: status_3,
                      backgroundColor: "rgb(215, 228, 189)",
                    },
                  ],
                }}
              ></Bar>
            ),
          });

          //per section count
          // _charts.push({
          //   col: 6,
          //   chart: (
          //     <Bar
          //       options={{
          //         plugins: {
          //           title: {
          //             display: true,
          //             text: "Συγκεντρωτικά ανά περιφέρεια άνα άξονα",
          //           },
          //           subtitle: {
          //             display: true,
          //             text: window["translations"]?.["tooltips"]?.[
          //               "chartSubtitle"
          //             ],
          //             padding: 12,
          //             font: {
          //               style: "italic",
          //             },
          //             position: "bottom",
          //           },
          //           legend: {
          //             display: true,
          //             position: "bottom",
          //           },
          //         },
          //         responsive: true,
          //         scales: {
          //           x: {
          //             stacked: true,
          //           },
          //           y: {
          //             stacked: true,
          //           },
          //         },
          //       }}
          //       data={{
          //         labels: labels,
          //         datasets: [
          //           {
          //             label: "Αγροτική παραγωγή & Πράσινη μετάβαση",
          //             data: section_1,
          //             backgroundColor: "rgb(0,206,209)",
          //           },
          //           {
          //             label: "Δίπλα στον πολίτη & Ποιότητα ζωής",
          //             data: section_2,
          //             backgroundColor: "rgb(148,0,211)",
          //           },
          //           {
          //             label: "Δίκτυα & Υποδομές",
          //             data: section_3,
          //             backgroundColor: "rgb(75,0,130)",
          //           },
          //           {
          //             label: "Επένδυση & αξία στην περιοχή",
          //             data: section_4,
          //             backgroundColor: "rgb(123,104,238)",
          //           },
          //         ],
          //       }}
          //     ></Bar>
          //   ),
          // });

          //per region budget
          _charts.push({
            col: 6,
            chart: (
              <Bar
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text:
                        _regions.length == 1
                          ? "Προϋπολογισμός έργων της περιφέρειας ".concat(
                              labels[0]
                            )
                          : "Συγκεντρωτικά προϋπολογισμών ανά περιφέρεια",
                    },
                    subtitle: {
                      display: true,

                      padding: 12,
                      font: {
                        style: "italic",
                      },
                      position: "bottom",
                    },
                    legend: {
                      display: false,
                      position: "bottom",
                    },
                  },
                  responsive: true,
                  scales: {},
                }}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Προϋπολογισμός",
                      data: budget,
                      backgroundColor: "rgb(219, 210, 234)",
                    },
                  ],
                }}
              ></Bar>
            ),
          });

          //per status budget
          _charts.push({
            col: 6,
            chart: (
              <Bar
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text:
                        _regions.length == 1
                          ? "Συγκεντρωτικά προυπολογισμών της περιφέρειας ".concat(
                              labels[0],
                              " άνα κατάσταση"
                            )
                          : "Συγκεντρωτικά προϋπολογισμών ανά περιφέρεια άνα κατάσταση",
                    },
                    subtitle: {
                      display: true,
                      text: window["translations"]?.["tooltips"]?.[
                        "chartSubtitle"
                      ],
                      padding: 12,
                      font: {
                        style: "italic",
                      },
                      position: "bottom",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Υπό σχεδιασμό",
                      data: budget_status_1,
                      backgroundColor: "rgb(219, 210, 234)",
                    },
                    {
                      label: "Σε εξέλιξη",
                      data: budget_status_2,
                      backgroundColor: "rgb(250, 215, 164)",
                    },
                    {
                      label: "Ολοκληρωμένα",
                      data: budget_status_3,
                      backgroundColor: "rgb(215, 228, 189)",
                    },
                  ],
                }}
              ></Bar>
            ),
          });

          //per subsection
          _charts.push({
            col: 12,
            chart: (
              <Bar
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text:
                        _regions.length == 1
                          ? "Συγκεντρωτικά της περιφέρειας ".concat(
                              labels[0],
                              " άνα κατηγορία"
                            )
                          : "Συγκεντρωτικά ανά περιφέρεια άνα κατηγορία",
                    },
                    subtitle: {
                      display: true,
                      text: window["translations"]?.["tooltips"]?.[
                        "chartSubtitle"
                      ],
                      padding: 12,
                      font: {
                        style: "italic",
                      },
                      position: "bottom",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
                data={{
                  labels: labels,
                  datasets: subsections,
                }}
              ></Bar>
            ),
          });
        }
      }

      if (filters.nomos) {
        let _nomoi = filters.nomos.split(",");

        if (_nomoi.length > 0) {
          var status_1 = [];

          var status_2 = [];

          var status_3 = [];

          var labels = [];

          var subsections = [];

          var subsectionsDatasets = {};

          var budget_status_1 = [];

          var budget_status_2 = [];

          var budget_status_3 = [];

          var budget = [];

          var section_1 = [];

          var section_2 = [];

          var section_3 = [];

          var section_4 = [];

          var count_projects = [];

          try {
            statistics.forEach((itm) => {
              if (
                itm.objecttype == "NOMOS" &&
                _nomoi.indexOf(itm.id.toString()) >= 0
              ) {
                count_projects.push(itm.count_projects);
                status_1.push(itm.count_projects_status_design);
                status_2.push(itm.count_projects_status_pending);
                status_3.push(itm.count_projects_status_completed);

                section_1.push(itm.count_sectionid_1);
                section_2.push(itm.count_sectionid_2);
                section_3.push(itm.count_sectionid_3);
                section_4.push(itm.count_sectionid_4);

                budget.push(itm.sum_budget);

                budget_status_1.push(itm.budget_projects_status_design);
                budget_status_2.push(itm.budget_projects_status_pending);
                budget_status_3.push(itm.budget_projects_status_completed);

                if (window["category_labels"]) {
                  Object.keys(window["category_labels"]).map((cl) => {
                    if (typeof subsectionsDatasets[cl] === "undefined") {
                      subsectionsDatasets[cl] = [];
                    }
                    subsectionsDatasets[cl].push(
                      itm["count_subsectionid_".concat(cl)]
                    );
                  });
                }

                labels.push(itm.label);
              }
            });

            Object.keys(window["category_labels"]).map((cl) => {
              subsections.push({
                label: window["category_labels"][cl],
                data: subsectionsDatasets[cl],
                backgroundColor: window["random_colors"][cl],
                hidden: window["category_labels_hidden"]
                  ? window["category_labels_hidden"].indexOf(parseInt(cl)) >= 0
                    ? false
                    : true
                  : false,
              });
            });
          } catch (e) {
            console.log(e);
          }

          //per status count
          _charts.push({
            col: 6,
            chart: (
              <Bar
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text:
                        _nomoi.length == 1
                          ? "Συγκεντρωτικά Π.Ε. ".concat(
                              labels[0],
                              " άνα κατάσταση"
                            )
                          : "Συγκεντρωτικά ανά περιφερειακή ενότητα άνα κατάσταση",
                    },
                    subtitle: {
                      display: true,
                      text: window["translations"]?.["tooltips"]?.[
                        "chartSubtitle"
                      ],
                      padding: 12,
                      font: {
                        style: "italic",
                      },
                      position: "bottom",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Υπό σχεδιασμό",
                      data: status_1,
                      backgroundColor: "rgb(219, 210, 234)",
                    },
                    {
                      label: "Σε εξέλιξη",
                      data: status_2,
                      backgroundColor: "rgb(250, 215, 164)",
                    },
                    {
                      label: "Ολοκληρωμένα",
                      data: status_3,
                      backgroundColor: "rgb(215, 228, 189)",
                    },
                  ],
                }}
              ></Bar>
            ),
          });

          //per section count
          // _charts.push({
          //   col: 6,
          //   chart: (
          //     <Bar
          //       options={{
          //         plugins: {
          //           title: {
          //             display: true,
          //             text: "Συγκεντρωτικά ανά περιφερειακή ενότητα άνα άξονα",
          //           },
          //           subtitle: {
          //             display: true,
          //             text: window["translations"]?.["tooltips"]?.[
          //               "chartSubtitle"
          //             ],
          //             padding: 12,
          //             font: {
          //               style: "italic",
          //             },
          //             position: "bottom",
          //           },
          //           legend: {
          //             display: true,
          //             position: "bottom",
          //           },
          //         },
          //         responsive: true,
          //         scales: {
          //           x: {
          //             stacked: true,
          //           },
          //           y: {
          //             stacked: true,
          //           },
          //         },
          //       }}
          //       data={{
          //         labels: labels,
          //         datasets: [
          //           {
          //             label: "Αγροτική παραγωγή & Πράσινη μετάβαση",
          //             data: section_1,
          //             backgroundColor: "rgb(0,206,209)",
          //           },
          //           {
          //             label: "Δίπλα στον πολίτη & Ποιότητα ζωής",
          //             data: section_2,
          //             backgroundColor: "rgb(148,0,211)",
          //           },
          //           {
          //             label: "Δίκτυα & Υποδομές",
          //             data: section_3,
          //             backgroundColor: "rgb(75,0,130)",
          //           },
          //           {
          //             label: "Επένδυση & αξία στην περιοχή",
          //             data: section_4,
          //             backgroundColor: "rgb(123,104,238)",
          //           },
          //         ],
          //       }}
          //     ></Bar>
          //   ),
          // });

          //per project count
          _charts.push({
            col: 6,
            chart: (
              <Bar
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text:
                        _nomoi.length == 1
                          ? "Σύνολο έργων Π.Ε. ".concat(labels[0])
                          : "Σύνολο έργων ανά περιφερειακή ενότητα",
                    },
                    subtitle: {
                      display: true,
                      text: window["translations"]?.["tooltips"]?.[
                        "chartSubtitle"
                      ],
                      padding: 12,
                      font: {
                        style: "italic",
                      },
                      position: "bottom",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Σύνολο έργων",
                      data: count_projects,
                      backgroundColor: "rgb(219, 210, 234)",
                    },
                  ],
                }}
              ></Bar>
            ),
          });

          //per status budget
          _charts.push({
            col: 6,
            chart: (
              <Bar
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text:
                        _nomoi.length == 1
                          ? "Συγκεντρωτικά προϋπολογισμού Π.Ε. ".concat(
                              labels[0],
                              " ανά κατάσταση"
                            )
                          : "Συγκεντρωτικά προϋπολογισμών ανά περιφερειακή ενότητα ανά κατάσταση",
                    },
                    subtitle: {
                      display: true,
                      text: window["translations"]?.["tooltips"]?.[
                        "chartSubtitle"
                      ],
                      padding: 12,
                      font: {
                        style: "italic",
                      },
                      position: "bottom",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Υπό σχεδιασμό",
                      data: budget_status_1,
                      backgroundColor: "rgb(219, 210, 234)",
                    },
                    {
                      label: "Σε εξέλιξη",
                      data: budget_status_2,
                      backgroundColor: "rgb(250, 215, 164)",
                    },
                    {
                      label: "Ολοκληρωμένα",
                      data: budget_status_3,
                      backgroundColor: "rgb(215, 228, 189)",
                    },
                  ],
                }}
              ></Bar>
            ),
          });

          //per subsection
          _charts.push({
            col: 12,
            chart: (
              <Bar
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text:
                        _nomoi.length == 1
                          ? "Συγκεντρωτικά Π.Ε. ".concat(
                              labels[0],
                              " ανά κατηγορία"
                            )
                          : "Συγκεντρωτικά ανά περιφερειακή ενότητα ανά κατηγορία",
                    },
                    subtitle: {
                      display: true,
                      text: window["translations"]?.["tooltips"]?.[
                        "chartSubtitle"
                      ],
                      padding: 12,
                      font: {
                        style: "italic",
                      },
                      position: "bottom",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
                data={{
                  labels: labels,
                  datasets: subsections,
                }}
              ></Bar>
            ),
          });
        }
      }

      if (filters.dimoi) {
      }

      // if (filters.subsection) {
      //   let _subsections = filters.subsection.split(",");

      //   if (_subsections.length > 0) {
      //     var status_1 = [];

      //     var status_2 = [];

      //     var status_3 = [];

      //     var labels = [];

      //     var subsections = [];

      //     var subsectionsDatasets = {};

      //     var budget_status_1 = [];

      //     var budget_status_2 = [];

      //     var budget_status_3 = [];

      //     var budget = [];

      //     var section_1 = [];

      //     var section_2 = [];

      //     var section_3 = [];

      //     var section_4 = [];

      //     var count_projects = [];

      //     try {
      //       statistics.forEach((itm) => {
      //         if (
      //           itm.objecttype == "NOMOS" &&
      //           _nomoi.indexOf(itm.id.toString()) >= 0
      //         ) {
      //           count_projects.push(itm.count_projects);
      //           status_1.push(itm.count_projects_status_design);
      //           status_2.push(itm.count_projects_status_pending);
      //           status_3.push(itm.count_projects_status_completed);

      //           section_1.push(itm.count_sectionid_1);
      //           section_2.push(itm.count_sectionid_2);
      //           section_3.push(itm.count_sectionid_3);
      //           section_4.push(itm.count_sectionid_4);

      //           budget.push(itm.sum_budget);

      //           budget_status_1.push(itm.budget_projects_status_design);
      //           budget_status_2.push(itm.budget_projects_status_pending);
      //           budget_status_3.push(itm.budget_projects_status_completed);

      //           if (window["category_labels"]) {
      //             Object.keys(window["category_labels"]).map((cl) => {
      //               if (typeof subsectionsDatasets[cl] === "undefined") {
      //                 subsectionsDatasets[cl] = [];
      //               }
      //               subsectionsDatasets[cl].push(
      //                 itm["count_subsectionid_".concat(cl)]
      //               );
      //             });
      //           }

      //           labels.push(itm.label);
      //         }
      //       });

      //       Object.keys(window["category_labels"]).map((cl) => {
      //         subsections.push({
      //           label: window["category_labels"][cl],
      //           data: subsectionsDatasets[cl],
      //           backgroundColor: window["random_colors"][cl],
      //           hidden: window["category_labels_hidden"]
      //             ? window["category_labels_hidden"].indexOf(parseInt(cl)) >= 0
      //               ? false
      //               : true
      //             : false,
      //         });
      //       });
      //     } catch (e) {
      //       console.log(e);
      //     }

      //     //per status count
      //     _charts.push({
      //       col: 6,
      //       chart: (
      //         <Bar
      //           options={{
      //             plugins: {
      //               title: {
      //                 display: true,
      //                 text: "Συγκεντρωτικά ανά περιφερειακή ενότητα άνα κατάσταση",
      //               },
      //               subtitle: {
      //                 display: true,
      //                 text: window["translations"]?.["tooltips"]?.[
      //                   "chartSubtitle"
      //                 ],
      //                 padding: 12,
      //                 font: {
      //                   style: "italic",
      //                 },
      //                 position: "bottom",
      //               },
      //               legend: {
      //                 display: true,
      //                 position: "bottom",
      //               },
      //             },
      //             responsive: true,
      //             scales: {
      //               x: {
      //                 stacked: true,
      //               },
      //               y: {
      //                 stacked: true,
      //               },
      //             },
      //           }}
      //           data={{
      //             labels: labels,
      //             datasets: [
      //               {
      //                 label: "Υπό σχεδιασμό",
      //                 data: status_1,
      //                 backgroundColor: "rgb(219, 210, 234)",
      //               },
      //               {
      //                 label: "Σε εξέλιξη",
      //                 data: status_2,
      //                 backgroundColor: "rgb(250, 215, 164)",
      //               },
      //               {
      //                 label: "Ολοκληρωμένα",
      //                 data: status_3,
      //                 backgroundColor: "rgb(215, 228, 189)",
      //               },
      //             ],
      //           }}
      //         ></Bar>
      //       ),
      //     });

      //     //per section count
      //     // _charts.push({
      //     //   col: 6,
      //     //   chart: (
      //     //     <Bar
      //     //       options={{
      //     //         plugins: {
      //     //           title: {
      //     //             display: true,
      //     //             text: "Συγκεντρωτικά ανά περιφερειακή ενότητα άνα άξονα",
      //     //           },
      //     //           subtitle: {
      //     //             display: true,
      //     //             text: window["translations"]?.["tooltips"]?.[
      //     //               "chartSubtitle"
      //     //             ],
      //     //             padding: 12,
      //     //             font: {
      //     //               style: "italic",
      //     //             },
      //     //             position: "bottom",
      //     //           },
      //     //           legend: {
      //     //             display: true,
      //     //             position: "bottom",
      //     //           },
      //     //         },
      //     //         responsive: true,
      //     //         scales: {
      //     //           x: {
      //     //             stacked: true,
      //     //           },
      //     //           y: {
      //     //             stacked: true,
      //     //           },
      //     //         },
      //     //       }}
      //     //       data={{
      //     //         labels: labels,
      //     //         datasets: [
      //     //           {
      //     //             label: "Αγροτική παραγωγή & Πράσινη μετάβαση",
      //     //             data: section_1,
      //     //             backgroundColor: "rgb(0,206,209)",
      //     //           },
      //     //           {
      //     //             label: "Δίπλα στον πολίτη & Ποιότητα ζωής",
      //     //             data: section_2,
      //     //             backgroundColor: "rgb(148,0,211)",
      //     //           },
      //     //           {
      //     //             label: "Δίκτυα & Υποδομές",
      //     //             data: section_3,
      //     //             backgroundColor: "rgb(75,0,130)",
      //     //           },
      //     //           {
      //     //             label: "Επένδυση & αξία στην περιοχή",
      //     //             data: section_4,
      //     //             backgroundColor: "rgb(123,104,238)",
      //     //           },
      //     //         ],
      //     //       }}
      //     //     ></Bar>
      //     //   ),
      //     // });

      //     //per project count
      //     _charts.push({
      //       col: 6,
      //       chart: (
      //         <Bar
      //           options={{
      //             plugins: {
      //               title: {
      //                 display: true,
      //                 text: "Σύνολο έργων ανά περιφερειακή ενότητα",
      //               },
      //               subtitle: {
      //                 display: true,
      //                 text: window["translations"]?.["tooltips"]?.[
      //                   "chartSubtitle"
      //                 ],
      //                 padding: 12,
      //                 font: {
      //                   style: "italic",
      //                 },
      //                 position: "bottom",
      //               },
      //               legend: {
      //                 display: true,
      //                 position: "bottom",
      //               },
      //             },
      //             responsive: true,
      //             scales: {
      //               x: {
      //                 stacked: true,
      //               },
      //               y: {
      //                 stacked: true,
      //               },
      //             },
      //           }}
      //           data={{
      //             labels: labels,
      //             datasets: [
      //               {
      //                 label: "Σύνολο έργων",
      //                 data: count_projects,
      //                 backgroundColor: "rgb(219, 210, 234)",
      //               },
      //             ],
      //           }}
      //         ></Bar>
      //       ),
      //     });

      //     //per status budget
      //     _charts.push({
      //       col: 6,
      //       chart: (
      //         <Bar
      //           options={{
      //             plugins: {
      //               title: {
      //                 display: true,
      //                 text: "Συγκεντρωτικά προϋπολογισμών ανά περιφερειακή ενότητα άνα κατάσταση",
      //               },
      //               subtitle: {
      //                 display: true,
      //                 text: window["translations"]?.["tooltips"]?.[
      //                   "chartSubtitle"
      //                 ],
      //                 padding: 12,
      //                 font: {
      //                   style: "italic",
      //                 },
      //                 position: "bottom",
      //               },
      //               legend: {
      //                 display: true,
      //                 position: "bottom",
      //               },
      //             },
      //             responsive: true,
      //             scales: {
      //               x: {
      //                 stacked: true,
      //               },
      //               y: {
      //                 stacked: true,
      //               },
      //             },
      //           }}
      //           data={{
      //             labels: labels,
      //             datasets: [
      //               {
      //                 label: "Υπό σχεδιασμό",
      //                 data: budget_status_1,
      //                 backgroundColor: "rgb(219, 210, 234)",
      //               },
      //               {
      //                 label: "Σε εξέλιξη",
      //                 data: budget_status_2,
      //                 backgroundColor: "rgb(250, 215, 164)",
      //               },
      //               {
      //                 label: "Ολοκληρωμένα",
      //                 data: budget_status_3,
      //                 backgroundColor: "rgb(215, 228, 189)",
      //               },
      //             ],
      //           }}
      //         ></Bar>
      //       ),
      //     });

      //     //per subsection
      //     _charts.push({
      //       col: 12,
      //       chart: (
      //         <Bar
      //           options={{
      //             plugins: {
      //               title: {
      //                 display: true,
      //                 text: "Συγκεντρωτικά ανά περιφερειακή ενότητα άνα κατηγορία",
      //               },
      //               subtitle: {
      //                 display: true,
      //                 text: window["translations"]?.["tooltips"]?.[
      //                   "chartSubtitle"
      //                 ],
      //                 padding: 12,
      //                 font: {
      //                   style: "italic",
      //                 },
      //                 position: "bottom",
      //               },
      //               legend: {
      //                 display: true,
      //                 position: "bottom",
      //               },
      //             },
      //             responsive: true,
      //             scales: {
      //               x: {
      //                 stacked: true,
      //               },
      //               y: {
      //                 stacked: true,
      //               },
      //             },
      //           }}
      //           data={{
      //             labels: labels,
      //             datasets: subsections,
      //           }}
      //         ></Bar>
      //       ),
      //     });
      //   }
      // }
    } else {
      var status_1 = [];

      var status_2 = [];

      var status_3 = [];

      var labels = [];

      var subsections = [];

      var subsectionsDatasets = {};

      var budget_status_1 = [];

      var budget_status_2 = [];

      var budget_status_3 = [];

      var budget = [];

      var section_1 = [];

      var section_2 = [];

      var section_3 = [];

      var section_4 = [];

      try {
        statistics.map((itm) => {
          if (itm.objecttype == "REGION") {
            status_1.push(itm.count_projects_status_design);
            status_2.push(itm.count_projects_status_pending);
            status_3.push(itm.count_projects_status_completed);

            section_1.push(itm.count_sectionid_1);
            section_2.push(itm.count_sectionid_2);
            section_3.push(itm.count_sectionid_3);
            section_4.push(itm.count_sectionid_4);

            budget.push(itm.sum_budget);

            budget_status_1.push(itm.budget_projects_status_design);
            budget_status_2.push(itm.budget_projects_status_pending);
            budget_status_3.push(itm.budget_projects_status_completed);

            if (window["category_labels"]) {
              Object.keys(window["category_labels"]).map((cl) => {
                if (typeof subsectionsDatasets[cl] === "undefined") {
                  subsectionsDatasets[cl] = [];
                }
                subsectionsDatasets[cl].push(
                  itm["count_subsectionid_".concat(cl)]
                );
              });
            }

            labels.push(itm.label);
          }
        });

        Object.keys(window["category_labels"]).map((cl) => {
          console.log(window["category_labels_hidden"]);
          subsections.push({
            label: window["category_labels"][cl],
            data: subsectionsDatasets[cl],
            backgroundColor: window["random_colors"][cl],
            hidden: window["category_labels_hidden"]
              ? window["category_labels_hidden"].indexOf(parseInt(cl)) >= 0
                ? false
                : true
              : false,
          });
        });
      } catch (e) {
        console.log(e);
      }

      //per status count
      _charts.push({
        col: 12,
        chart: (
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Συγκεντρωτικά ανά περιφέρεια άνα κατάσταση",
                },
                subtitle: {
                  display: true,
                  text: window["translations"]?.["tooltips"]?.["chartSubtitle"],
                  padding: 12,
                  font: {
                    style: "italic",
                  },
                  position: "bottom",
                },
                legend: {
                  display: true,
                  position: "bottom",
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  label: "Υπό σχεδιασμό",
                  data: status_1,
                  backgroundColor: "rgb(219, 210, 234)",
                },
                {
                  label: "Σε εξέλιξη",
                  data: status_2,
                  backgroundColor: "rgb(250, 215, 164)",
                },
                {
                  label: "Ολοκληρωμένα",
                  data: status_3,
                  backgroundColor: "rgb(215, 228, 189)",
                },
              ],
            }}
          ></Bar>
        ),
      });

      //per section count
      // _charts.push({
      //   col: 12,
      //   chart: (
      //     <Bar
      //       options={{
      //         plugins: {
      //           title: {
      //             display: true,
      //             text: "Συγκεντρωτικά ανά περιφέρεια άνα άξονα",
      //           },
      //           subtitle: {
      //             display: true,
      //             text: window["translations"]?.["tooltips"]?.["chartSubtitle"],
      //             padding: 12,
      //             font: {
      //               style: "italic",
      //             },
      //             position: "bottom",
      //           },
      //           legend: {
      //             display: true,
      //             position: "bottom",
      //           },
      //         },
      //         responsive: true,
      //         scales: {
      //           x: {
      //             stacked: true,
      //           },
      //           y: {
      //             stacked: true,
      //           },
      //         },
      //       }}
      //       data={{
      //         labels: labels,
      //         datasets: [
      //           {
      //             label: "Αγροτική παραγωγή & Πράσινη μετάβαση",
      //             data: section_1,
      //             backgroundColor: "rgb(0,206,209)",
      //           },
      //           {
      //             label: "Δίπλα στον πολίτη & Ποιότητα ζωής",
      //             data: section_2,
      //             backgroundColor: "rgb(148,0,211)",
      //           },
      //           {
      //             label: "Δίκτυα & Υποδομές",
      //             data: section_3,
      //             backgroundColor: "rgb(75,0,130)",
      //           },
      //           {
      //             label: "Επένδυση & αξία στην περιοχή",
      //             data: section_4,
      //             backgroundColor: "rgb(123,104,238)",
      //           },
      //         ],
      //       }}
      //     ></Bar>
      //   ),
      // });

      //per region budget
      _charts.push({
        col: 12,
        chart: (
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Συγκεντρωτικά προϋπολογισμών ανά περιφέρεια",
                },
                subtitle: {
                  display: true,
                  text: window["translations"]?.["tooltips"]?.["chartSubtitle"],
                  padding: 12,
                  font: {
                    style: "italic",
                  },
                  position: "bottom",
                },
                legend: {
                  display: false,
                  position: "bottom",
                },
              },
              responsive: true,
              scales: {},
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  label: "Προϋπολογισμός",
                  data: budget,
                  backgroundColor: "rgb(219, 210, 234)",
                },
              ],
            }}
          ></Bar>
        ),
      });

      //per status budget
      _charts.push({
        col: 12,
        chart: (
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Συγκεντρωτικά προϋπολογισμών ανά περιφέρεια άνα κατάσταση",
                },
                subtitle: {
                  display: true,
                  text: window["translations"]?.["tooltips"]?.["chartSubtitle"],
                  padding: 12,
                  font: {
                    style: "italic",
                  },
                  position: "bottom",
                },
                legend: {
                  display: true,
                  position: "bottom",
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  label: "Υπό σχεδιασμό",
                  data: budget_status_1,
                  backgroundColor: "rgb(219, 210, 234)",
                },
                {
                  label: "Σε εξέλιξη",
                  data: budget_status_2,
                  backgroundColor: "rgb(250, 215, 164)",
                },
                {
                  label: "Ολοκληρωμένα",
                  data: budget_status_3,
                  backgroundColor: "rgb(215, 228, 189)",
                },
              ],
            }}
          ></Bar>
        ),
      });

      //per subsection
      _charts.push({
        col: 12,
        chart: (
          <Bar
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Συγκεντρωτικά ανά περιφέρεια άνα κατηγορία",
                },
                subtitle: {
                  display: true,
                  text: window["translations"]?.["tooltips"]?.["chartSubtitle"],
                  padding: 12,
                  font: {
                    style: "italic",
                  },
                  position: "bottom",
                },
                legend: {
                  display: true,
                  position: "bottom",
                },
              },
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={{
              labels: labels,
              datasets: subsections,
            }}
          ></Bar>
        ),
      });
    }

    return _charts;
  };

  const formatDataSets = (type) => {
    let _data = [];
    let _dataset = {};
    /*
    record_id
id
type
label
count_projects
count_projects_status_design
count_projects_status_pending
count_projects_status_completed
sum_budget*/

    switch (type) {
      case "all":
        var count_projects = 0;
        var count_projects_status_design = 0;
        var count_projects_status_pending = 0;
        var count_projects_status_completed = 0;
        var sum_budget = 0;

        statistics?.forEach((itm) => {
          if (itm.objecttype === "REGION") {
            count_projects = count_projects + parseInt(itm.count_projects);
            count_projects_status_design =
              count_projects_status_design +
              parseInt(itm.count_projects_status_design);
            count_projects_status_pending =
              count_projects_status_pending +
              parseInt(itm.count_projects_status_pending);
            count_projects_status_completed =
              count_projects_status_completed +
              parseInt(itm.count_projects_status_completed);
            sum_budget = sum_budget + parseFloat(itm.sum_budget);
          }
        });

        _dataset = {
          labels: ["Υπό σχεδιασμό", "Σε εξέλιξη", "Ολοκληρωμένο"],
          datasets: [
            {
              label: "# Αριθμός έργων",
              data: [
                count_projects_status_design,
                count_projects_status_pending,
                count_projects_status_completed,
              ],
              backgroundColor: [
                "rgba(105, 74, 156, 0.2)",
                "rgba(173, 99, 48, 0.2)",
                "rgba(83, 130, 73, 0.2)",
              ],
              borderColor: [
                "rgba(105, 74, 156, 1)",
                "rgba(173, 99, 48, 1)",
                "rgba(83, 130, 73, 1)",
              ],
              borderWidth: 1,
            },
          ],
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: "right",
              },
            },
          },
        };

        break;

      case "regions":
        var count_projects = [];
        var count_projects_status_design = 0;
        var count_projects_status_pending = 0;
        var count_projects_status_completed = 0;
        var sum_budget = 0;
        var labels = [];
        var colors = [];

        statistics?.forEach((itm) => {
          if (itm.objecttype === "REGION") {
            labels.push(itm.label.concat(" (", itm.count_projects, ")"));
            count_projects.push(itm.count_projects);

            colors.push(random_rgba());
            count_projects_status_design =
              count_projects_status_design +
              parseInt(itm.count_projects_status_design);
            count_projects_status_pending =
              count_projects_status_pending +
              parseInt(itm.count_projects_status_pending);
            count_projects_status_completed =
              count_projects_status_completed +
              parseInt(itm.count_projects_status_completed);
            sum_budget = sum_budget + parseFloat(itm.sum_budget);
          }
        });

        _dataset = {
          labels: labels,
          datasets: [
            {
              label: "# Αριθμός έργων",
              data: count_projects,
              backgroundColor: [...colors],
              borderColor: [...colors],
              borderWidth: 1,
            },
          ],
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: "right",
              },
            },
          },
        };
        break;

      case "pe":
        var count_projects = [];
        var count_projects_status_design = 0;
        var count_projects_status_pending = 0;
        var count_projects_status_completed = 0;
        var sum_budget = 0;
        var labels = [];
        var colors = [];

        statistics?.forEach((itm) => {
          if (itm.objecttype === "NOMOS") {
            labels.push(itm.label.concat(" (", itm.count_projects, ")"));
            count_projects.push(itm.count_projects);

            colors.push(random_rgba());
            count_projects_status_design =
              count_projects_status_design +
              parseInt(itm.count_projects_status_design);
            count_projects_status_pending =
              count_projects_status_pending +
              parseInt(itm.count_projects_status_pending);
            count_projects_status_completed =
              count_projects_status_completed +
              parseInt(itm.count_projects_status_completed);
            sum_budget = sum_budget + parseFloat(itm.sum_budget);
          }
        });

        _dataset = {
          labels: labels,
          datasets: [
            {
              label: "# Αριθμός έργων",
              data: count_projects,
              backgroundColor: [...colors],
              borderColor: [...colors],
              borderWidth: 1,
            },
          ],
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: "right",
              },
            },
          },
        };
        break;

      case "sections":
        var count_projects = [];
        var count_projects_status_design = 0;
        var count_projects_status_pending = 0;
        var count_projects_status_completed = 0;
        var sum_budget = 0;
        var labels = [];
        var colors = [];

        statistics?.forEach((itm) => {
          if (itm.objecttype === "SECTIONS") {
            labels.push(itm.label.concat(" (", itm.count_projects, ")"));
            count_projects.push(itm.count_projects);

            colors.push(random_rgba());
            count_projects_status_design =
              count_projects_status_design +
              parseInt(itm.count_projects_status_design);
            count_projects_status_pending =
              count_projects_status_pending +
              parseInt(itm.count_projects_status_pending);
            count_projects_status_completed =
              count_projects_status_completed +
              parseInt(itm.count_projects_status_completed);
            sum_budget = sum_budget + parseFloat(itm.sum_budget);
          }
        });

        _dataset = {
          labels: labels,
          datasets: [
            {
              label: "# Αριθμός έργων",
              data: count_projects,
              backgroundColor: [...colors],
              borderColor: [...colors],
              borderWidth: 1,
            },
          ],
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: "right",
              },
            },
          },
        };
        break;

      case "subsections":
        var count_projects = [];
        var count_projects_status_design = 0;
        var count_projects_status_pending = 0;
        var count_projects_status_completed = 0;
        var sum_budget = 0;
        var labels = [];
        var colors = [];

        statistics?.forEach((itm) => {
          if (itm.objecttype === "SUBSECTIONS") {
            labels.push(itm.label.concat(" (", itm.count_projects, ")"));
            count_projects.push(itm.count_projects);

            colors.push(random_rgba());
            count_projects_status_design =
              count_projects_status_design +
              parseInt(itm.count_projects_status_design);
            count_projects_status_pending =
              count_projects_status_pending +
              parseInt(itm.count_projects_status_pending);
            count_projects_status_completed =
              count_projects_status_completed +
              parseInt(itm.count_projects_status_completed);
            sum_budget = sum_budget + parseFloat(itm.sum_budget);
          }
        });

        _dataset = {
          labels: labels,
          datasets: [
            {
              label: "# Αριθμός έργων",
              data: count_projects,
              backgroundColor: [...colors],
              borderColor: [...colors],
              borderWidth: 1,
            },
          ],
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: "right",
              },
            },
          },
        };
        break;
    }

    return _dataset;
  };

  return (
    <section className="container w-md-50 mt-3" id="search_results_stats">
      <div className="row">
        {createCharts().map((itm) => {
          return (
            <div className={"col-md-".concat(itm.col, " p-2")}>
              <div className="border rounded p-4">{itm.chart}</div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default ChartsPage;
