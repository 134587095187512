import { useEffect, useState } from "react";
import "./styles.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SearchPanel from "./searchpanel";
import Footer from "./footer";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import Menu from "./menu";
import ChartsPage from "./charts";
import { truncate, formatBudget, formatImage, removeAccents } from "./lib";

function Homepage() {
  const [loaded, setLoaded] = useState(false);

  const [projects, setProjects] = useState([]);

  const [seeMoreProjects, setSeeMoreProjects] = useState([]);

  const [total, setTotal] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const [highlightprojects, setHighlightProjects] = useState([]);

  const [filters, setFilters] = useState("");

  const [filtersObj, setFiltersObj] = useState(null);

  const [showCharts, setShowCarts] = useState(false);

  const [welcomeCategory, setWelcomeCategory] = useState(null);

  const params = useParams();

  useEffect(() => {
    if (!loaded) {
      fetchWelcomeCategory();
      fetchProjects(1);
      fetchHighlightProjects();
      window.scrollTo(0, 0);
    }
    setLoaded(true);
  }, [loaded, params]);

  const fetchWelcomeCategory = () => {
    fetch(
      "/rest/content/data.php?module=categories&template=L&recordid=".concat(
        window["welcome_category_id"]
      )
    )
      .then((response) => response.json())
      .then((data) => {
        setWelcomeCategory(data.data[0]);
      })
      .catch((error) => console.error(error));
  };

  const fetchHighlightProjects = () => {
    fetch("/rest/content/data.php?module=highlight&limit=12")
      .then((response) => response.json())
      .then((data) => {
        setHighlightProjects(data.data);
      })
      .catch((error) => console.error(error));
  };

  const updateFilters = (filters) => {
    let _params = [];

    setCurrentPage(1);

    Object.keys(filters).map((el) => {
      if (filters[el]) {
        _params.push(el.concat("=", filters[el]));
      }
    });

    setFiltersObj(filters);

    setSeeMoreProjects([]);

    fetchProjects(0, _params.join("&"), filters);
    setFilters(_params.join("&"));
  };

  const fetchProjects = (page, filters, _filtersObj) => {
    let url =
      "/rest/content/data.php?module=projects&limit=12&{filters}&page=".concat(
        page
      );

    url = url.replace("{filters}", filters);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCurrentPage(page);

        if (page > 1) {
          let p = [...projects];

          data.data.forEach((a) => {
            p.push(a);
          });

          setProjects(p);
        } else {
          setProjects(data.data);
        }

        setTotal(data.total);

        if (_filtersObj) {
          if (_filtersObj.dimoi && _filtersObj.nomos) {
            fetchSeeMoreProjects(1, "nomos=" + _filtersObj.nomos);
          }

          if (!_filtersObj.dimoi && _filtersObj.nomos && _filtersObj.region) {
            fetchSeeMoreProjects(1, "region=" + _filtersObj.region);
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchSeeMoreProjects = (page, filters) => {
    let url =
      "/rest/content/data.php?module=projects&limit=4&{filters}&page=".concat(
        page
      );

    url = url.replace("{filters}", filters);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCurrentPage(page);
        if (page > 1) {
          let p = [...projects];

          data.data.forEach((a) => {
            p.push(a);
          });

          setSeeMoreProjects(p);
        } else {
          setSeeMoreProjects(data.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const projectCard = (project) => {
    let badgeBgColor = "";
    let statusTooltipProperty = "";
    switch (project.status?.toString()) {
      case "3":
        badgeBgColor = "#538249";
        statusTooltipProperty = "complete";
        break;

      case "2":
        badgeBgColor = "#AD6330";
        statusTooltipProperty = "pending";
        break;

      case "1":
        badgeBgColor = "#694A9C";
        statusTooltipProperty = "design";
        break;
    }

    let regions = project.region.split(",");

    let regionsContent = [];
    let moreRegions = [];

    regions.forEach((r, i) => {
      if (i <= 1) {
        if (i == 0) {
          regionsContent.push(
            <span className="project-badge p-2 pt-1 pb-1">
              {removeAccents(r)}
            </span>
          );
        } else {
          regionsContent.push(
            <span className="project-badge p-2 pt-1 pb-1   d-inline-block">
              {removeAccents(r)}
            </span>
          );
        }
      } else {
        moreRegions.push(
          <span className="project-badge-more p-2 pt-1 pb-1">
            {removeAccents(r)}
          </span>
        );
      }
    });

    if (moreRegions.length > 0) {
      regionsContent.push(
        <span
          className="project-badge p-2 pt-1 pb-1 d-inline-block"
          data-bs-toggle="collapse"
          data-bs-target={"#collapse-".concat(project.project_id)}
        >
          +{moreRegions.length}
        </span>
      );
    }

    return (
      <div
        className={"project-card h-100 position-relative ".concat(
          project.highlight == "1" ? "highlighted" : ""
        )}
      >
        <div
          className="project-imgbox"
          style={{
            backgroundImage: "url(".concat(
              project.image ? project.image : formatImage(project.subsectionid),
              ")"
            ),
          }}
        >
          <div className="project-imgbox-filter"></div>
          {project.highlight ? (
            window["translations"]?.tooltips?.project?.highlighted ? (
              <div className="project-star">
                <a
                  data-tooltip-id={"my-tooltip-highlight-" + project.project_id}
                  data-tooltip-content={
                    window["translations"]?.tooltips?.project?.highlighted
                  }
                >
                  <i
                    className="fas fa-star shadow-sm"
                    style={{ color: "#00aeef" }}
                  ></i>{" "}
                  <Tooltip id={"my-tooltip-highlight-" + project.project_id} />
                </a>
              </div>
            ) : (
              <div className="project-star">
                <i
                  className="fas fa-star shadow-sm"
                  style={{ color: "#00aeef" }}
                ></i>
              </div>
            )
          ) : (
            ""
          )}

          {project.restoration ? (
            window["translations"]?.tooltips?.project?.sxedio_anasygkrothshs ? (
              <div className="project-restoration">
                <a
                  data-tooltip-id={
                    "my-tooltip-restoration-" + project.project_id
                  }
                  data-tooltip-content={
                    window["translations"]?.tooltips?.project
                      ?.sxedio_anasygkrothshs
                  }
                >
                  <img src="/images/wip.svg"></img>
                  <Tooltip
                    id={"my-tooltip-restoration-" + project.project_id}
                  />
                </a>
              </div>
            ) : (
              <div className="project-restoration">
                <img src="/images/wip.svg"></img>
              </div>
            )
          ) : (
            ""
          )}

          <a
            className="project-status"
            style={{ backgroundColor: badgeBgColor }}
            data-tooltip-id={"my-tooltip-status-" + project.project_id}
            data-tooltip-content={
              window["translations"]?.tooltips?.status[statusTooltipProperty]
            }
          >
            <Tooltip id={"my-tooltip-status-" + project.project_id} />
          </a>
          <div className="project-regions">{regionsContent}</div>
        </div>
        <div className="project-body p-2 bg-white">
          <Link to={"/".concat(project.project_id)}>
            <span className="title-display-3 d-block">
              <strong>{project.subsection}</strong>
            </span>
            <span className="title-display-4 d-block ">{project.section}</span>

            <h3 className="title-display-2 d-block mt-2 mb-2">
              {truncate(project.title, 80)}
            </h3>
          </Link>
        </div>
        <div className="project-footer d-flex p-2  pe-3">
          <span className="title-display-5 flex-fill ">
            {project.budget && formatBudget(project.budget) ? (
              "Προϋπολ."
            ) : (
              <div style={{ height: "21px" }}></div>
            )}
          </span>
          <span className="title-display-5">
            <strong>
              {project.budget ? formatBudget(project.budget) : ""}
            </strong>
          </span>
        </div>
        <div
          className="projects-more-regions collapse"
          id={"collapse-".concat(project.project_id)}
        >
          {moreRegions}
        </div>
      </div>
    );
  };

  const SeeMore = () => {
    if (seeMoreProjects.length > 0) {
      let seeMoreTitle = "";
      let seeMoreUrl = "";

      if (filtersObj.dimoi && filtersObj.nomos) {
        if (filtersObj.nomos.split(",").length > 1) {
          seeMoreTitle =
            "Δείτε επίσης έργα από τις επιλεγμένες περιφερειακές ενότητες";
        } else {
          seeMoreTitle =
            "Δείτε επίσης έργα από την επιλεγμένη περιφερειακή ενότητα";
        }
        seeMoreUrl = "pe=" + filtersObj.nomos;
        if (filtersObj.region) {
          seeMoreUrl =
            "pe=" + filtersObj.nomos + "&regions=" + filtersObj.region;
        }
      }

      if (!filtersObj.dimoi && filtersObj.nomos && filtersObj.region) {
        if (filtersObj.region.split(",").length > 1) {
          seeMoreTitle = "Δείτε επίσης έργα από τις επιλεγμένες περιφέρειες";
        } else {
          seeMoreTitle = "Δείτε επίσης έργα από την επιλεγμένη περιφέρεια";
        }

        seeMoreUrl = "regions=" + filtersObj.region;
      }
      return (
        <section
          className="container w-md-50 mt-5 p-3 ps-4 pe-4 rounded"
          style={{ backgroundColor: "#eaeaea" }}
          id="see_more_results"
        >
          <h2 class="title-display-2 mt-3 text-center">{seeMoreTitle}</h2>
          <div className="row mt-3">
            {seeMoreProjects?.map((project) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 p-2">
                  {projectCard(project)}
                </div>
              );
            })}
          </div>
          <div className="mt-3 text-end">
            <span
              onClick={() => {
                window.location.replace("/?" + seeMoreUrl);
              }}
              style={{ cursor: "pointer" }}
            >
              Δείτε τα όλα <i className="fas fa-external-link-alt"></i>
            </span>
          </div>
        </section>
      );
    }

    return "";
  };

  const highlightProjectCard = (project) => {
    let badgeBgColor = "#eaeaea";

    switch (project.status?.toString()) {
      case "3":
        badgeBgColor = "#538249";

        break;

      case "2":
        badgeBgColor = "#AD6330";
        break;

      case "1":
        badgeBgColor = "#694A9C";
        break;
    }

    return (
      <Link to={"/".concat(project.project_id)}>
        <div
          className="project-highlight-card"
          style={{
            backgroundImage: "url(".concat(
              project.image ? project.image : formatImage(project.subsectionid),
              ")"
            ),
          }}
        >
          <div className="project-highlight-imgbox-filter"></div>
          <span
            className="project-highlight-badge p-2 pt-1 pb-1"
            style={{ backgroundColor: badgeBgColor }}
          >
            {project.statustext}
          </span>

          <div className=" p-4">
            <div className="highlight-title p-3">
              <div className="highlight-title-display-4 ">
                {project.region
                  ? project.region.replaceAll(", ", ",").replaceAll(",", ", ")
                  : ""}
              </div>
              <div className="highlight-title-display-1  mt-3 pe-2">
                {truncate(project.title, 80)}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <>
      <Menu></Menu>
      <div
        className="container-fluid hero-banner ps-2 pe-2"
        style={{
          backgroundImage: "url(/images/hero-bg.jpg)",
          marginTop: "60px",
          minHeight: "700px",
        }}
      >
        <section
          className="container w-md-50 mb-4 highlight-container"
          style={{ paddingTop: "50px" }}
        >
          <div className="text-center mb-5 mt-5">
            {/* <img src="/images/logo.png" className="img-fluid mt-3"></img> */}
          </div>
          <div className="header-title mt-5">
            <h1 className="title-display-0 text-white text-center ">
              Περιφερειακά Σχέδια Ανάπτυξης
            </h1>
          </div>

          {highlightprojects.length > 0 ? (
            <OwlCarousel
              className="owl-theme pb-3 mb-3 mt-3"
              loop
              margin={20}
              height={500}
              nav
              merge
              responsive={{
                0: {
                  items: 1,
                  nav: true,
                },
                768: {
                  items: 2,
                  nav: true,
                },
                992: {
                  items: 3,
                  nav: true,
                },
              }}
            >
              {highlightprojects.map((project) => {
                return (
                  <div className="item">{highlightProjectCard(project)}</div>
                );
              })}
            </OwlCarousel>
          ) : (
            ""
          )}
          {window["translations"]["subtitle"]
            ? parse(window["translations"]["subtitle"])
            : ""}
        </section>
      </div>
      <div className="container w-md-50 mt-5 ps-2 pe-2">
        <div className="pt-3">
          {welcomeCategory ? parse(welcomeCategory.content) : ""}
        </div>
      </div>
      <section className="container w-md-50 mt-5 ps-2 pe-2">
        <SearchPanel updateFilters={updateFilters} total={total}></SearchPanel>
        <div className="text-end mt-3">
          <button
            className="btn text-secondary btn-sm"
            onClick={() => {
              if (!showCharts) {
                setShowCarts(true);
              } else {
                setShowCarts(false);
              }
            }}
          >
            {showCharts ? (
              <>
                <i className="fas fa-th-list me-2"></i> Λίστα έργων
              </>
            ) : (
              <>
                <i className="fas fa-chart-pie me-2"></i>
                Συγκεντρωτικά στοιχεία
              </>
            )}
          </button>
        </div>
      </section>

      {showCharts ? (
        <ChartsPage filters={filters} filtersObj={filtersObj}></ChartsPage>
      ) : (
        <section className="container w-md-50 mt-3" id="search_results">
          <div className="row">
            {projects?.map((project) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 p-2">
                  {projectCard(project)}
                </div>
              );
            })}
          </div>
          {total > projects?.length ? (
            <div className="mt-3 text-center">
              <button
                className="btn btn-link border rounded"
                onClick={() => {
                  fetchProjects(currentPage + 1, filters);
                }}
              >
                Εμφάνιση περισσότερων
              </button>
            </div>
          ) : !total ? (
            <div>Δεν βρέθηκαν αποτελέσματα</div>
          ) : null}
        </section>
      )}
      <SeeMore></SeeMore>
      <Footer></Footer>
    </>
  );
}

export default Homepage;
