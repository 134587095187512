import Multiselect from "multiselect-react-dropdown";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Map from "ol/Map";
import { useEffect, useRef, useState } from "react";
import OSM from "ol/source/OSM.js";
import TileLayer from "ol/layer/Tile.js";
import View from "ol/View.js";
import GeoJSON from "ol/format/GeoJSON.js";
import VectorSource from "ol/source/Vector.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { bbox as bboxStrategy } from "ol/loadingstrategy.js";
import Select from "ol/interaction/Select.js";
import { altKeyOnly, click, pointerMove } from "ol/events/condition.js";
import "ol/ol.css";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import Switch from "react-switch";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import moment from "moment/moment";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

var isMobile = require("is-mobile");

const months = [
  "Ιαν",
  "Φεβ",
  "Μαρ",
  "Απρ",
  "Μαη",
  "Ιουν",
  "Ιουλ",
  "Αυγ",
  "Σεπ",
  "Οκτ",
  "Νοε",
  "Δεκ",
];

function SearchPanel(props) {
  const [loaded, setLoaded] = useState(false);

  const [URLParamasLoaded, setURLParamasLoaded] = useState(false);

  const [statuses, setStatuses] = useState([]);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [regions, setRegions] = useState([]);
  const [nomoi, setNomoi] = useState([]);
  const [dimoi, setDimoi] = useState([]);
  const [responsibilities, setResponsibilities] = useState([]);
  const [showMap, setShowMap] = useState(!isMobile());

  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState(null);

  const [searchTitle, setSearchTitle] = useState(null);

  const regiondd = useState(null);

  window["fetchRestoration"] = function () {
    setFilters({ restoration: true });

    props.updateFilters({ restoration: true });
  };

  useEffect(() => {
    if (!loaded) {
      // return (
      //   "https://geoportal.ermis-f.eu/geoserver/wfs?service=WFS&" +
      //   "version=2.0.0&request=GetFeature&typeNames=geonode:Perifereiakes_enotites&" +
      //   "outputFormat=json&srsname=EPSG:3857&" +
      //   "bbox=" +
      //   extent.join(",") +
      //   ",EPSG:3857"
      // );

      let perifereies = new VectorLayer({
        maxZoom: 7,
        source: new VectorSource({
          format: new GeoJSON(),
          url: "/rest/prefectures.json",
          // url: function (extent) {
          //   return (
          //     "http://geodata.gov.gr/geoserver/ows?service=WFS&" +
          //     "version=2.0.0&request=GetFeature&typeNames=geodata.gov.gr:d7f50467-e5ef-49ac-a7ce-15df3e2ed738&" +
          //     "outputFormat=json&srsname=EPSG:3857&" +
          //     "bbox=" +
          //     extent.join(",") +
          //     ",EPSG:3857"
          //   );
          // },
          // strategy: bboxStrategy,
        }),
        style: {
          "stroke-width": 0.75,
          "stroke-color": "white",
          "fill-color": "rgba(42,109,134,1)",
        },
      });

      let nomous = new VectorLayer({
        minZoom: 7,
        source: new VectorSource({
          format: new GeoJSON(),
          url: "/rest/nomoi.json",
          // url: function (extent) {
          //   return (
          //     "http://geodata.gov.gr/geoserver/ows?service=WFS&" +
          //     "version=2.0.0&request=GetFeature&typeNames=geodata.gov.gr:e81089cb-f48c-4c5b-ac77-aa3028fe31fb&" +
          //     "outputFormat=json&srsname=EPSG:3857&" +
          //     "bbox=" +
          //     extent.join(",") +
          //     ",EPSG:3857"
          //   );
          // },
          // strategy: bboxStrategy,
        }),
        style: {
          "stroke-width": 0.75,
          "stroke-color": "white",
          "fill-color": "rgba(42,109,134,1)",
        },
      });

      let dimoi = new VectorLayer({
        source: new VectorSource({
          format: new GeoJSON(),
          url: function (extent) {
            return (
              "http://geodata.gov.gr/geoserver/ows?service=WFS&" +
              "version=2.0.0&request=GetFeature&typeNames=geodata.gov.gr:c7b5978b-aca9-4d74-b8a5-d3a48d02f6d0&" +
              "outputFormat=json&srsname=EPSG:3857&" +
              "bbox=" +
              extent.join(",") +
              ",EPSG:3857"
            );
          },
          strategy: bboxStrategy,
        }),
        style: {
          "stroke-width": 0.75,
          "stroke-color": "white",
          "fill-color": "rgba(42,109,134,1)",
        },
      });

      window["map"] = new Map({
        layers: [perifereies, nomous],
        target: "map",
        view: new View({
          center: [2642432.187, 4601208.467],
          zoom: 6,
        }),
      });

      let re = [];

      // dimoi.getSource().once("change", function (event) {
      //   let source = event.target;
      //   if (source.getState() === "ready") {
      //     source.getFeatures().forEach((feature) => {
      //       // console.log(feature.getProperties());
      //       console.log(
      //         "INSERT INTO `dimoi` (`id`, `name`,`KWD_YPES`) VALUES ('" +
      //           feature
      //             .getId()
      //             .replace("c7b5978b-aca9-4d74-b8a5-d3a48d02f6d0.", "") +
      //           "', '" +
      //           feature.getProperties()["NAME"] +
      //           "', '" +
      //           feature.getProperties()["KWD_YPES"] +
      //           "');"
      //       );
      //       // console.log({
      //       //   id: feature.getId(),
      //       //   name: feature.getProperties()["PER"],
      //       // });
      //     });
      //   }
      // });

      fetchFilters("status");
      fetchFilters("sections");
      fetchFilters("subsections");
      fetchFilters("regions");
      fetchFilters("nomoi");
      fetchFilters("responsibility");
      fetchFilters("dimoi");
    }

    setLoaded(true);

    if (loaded) {
      if (regions.length > 0 && nomoi.length > 0 && dimoi.length > 0) {
        updateOnSelect();
        getFiltersFromURL();
        setURLParamasLoaded(true);
      }
    }
  }, [loaded, regions, nomoi, dimoi]);

  const updateOnSelect = () => {
    //window["map"].removeInteraction(window["selectPointerMove"]);

    if (!window["selectPointerMove"]) {
      window["selectPointerMove"] = new Select({
        //condition: pointerMove,
        style: new Style({
          fill: new Fill({
            color: "#eeeeee",
          }),
          stroke: new Stroke({
            color: "rgba(42,109,134,1)",
            width: 2,
          }),
          transition: "0.5s",
        }),
      });

      window["map"].addInteraction(window["selectPointerMove"]);

      window["selectPointerMove"].on("select", function (e) {
        try {
          let featureId = e.selected[0].getId();

          let splitId = featureId.split(".");

          switch (splitId[0]) {
            case "d7f50467-e5ef-49ac-a7ce-15df3e2ed738":
              let selectedRegion = regions.filter((el) => {
                let _region = splitId[1];

                return _region === el.value;
              });

              let _filters = { ...filters };

              _filters.region = selectedRegion;

              setFilters(_filters);

              //fetchFilters("nomoi", _filters);

              onChangeFilters("region", selectedRegion);

              break;

            case "e81089cb-f48c-4c5b-ac77-aa3028fe31fb":
              console.log(nomoi);
              let selectedNomos = nomoi.filter((el) => {
                return splitId[1] === el.value;
              });

              let _filters2 = { ...filters };

              _filters2.nomos = selectedNomos;

              setFilters(_filters2);

              //fetchFilters("nomoi", _filters);

              onChangeFilters("nomos", selectedNomos);

              break;
          }
        } catch (e) {}
      });
    }
  };

  const onChangeFilters = (property, value) => {
    let _filters = { ...filters };

    let _selectedFilters = { ...filters };

    _selectedFilters[property] = value;

    _filters[property] = value;

    // if (property == "dimoi") {
    //   if (!_selectedFilters["nomos"]) {
    //     let _nomosIds = [];

    //     value.forEach((itm, idx) => {
    //       if (_nomosIds.indexOf(itm) < 0) {
    //         _nomosIds.push(itm.nomos.toString());
    //       }
    //     });

    //     let _nomos = [];

    //     nomoi.forEach((el, idx) => {
    //       if (_nomosIds.indexOf(el.value.toString()) >= 0) {
    //         _nomos.push(el);
    //       }
    //     });

    //     _selectedFilters["nomos"] = _nomos;
    //     _filters["nomos"] = _nomos;
    //   }
    //   if (!_selectedFilters["region"]) {
    //     let _regionIds = [];

    //     value.forEach((itm, idx) => {
    //       if (_regionIds.indexOf(itm) < 0) {
    //         _regionIds.push(itm.region.toString());
    //       }
    //     });

    //     let _regions = [];

    //     regions.forEach((el, idx) => {
    //       if (_regionIds.indexOf(el.value.toString()) >= 0) {
    //         _regions.push(el);
    //       }
    //     });

    //     _selectedFilters["region"] = _regions;
    //     _filters["region"] = _regions;
    //   }
    // }

    updateFilters(_filters);

    setFilters(_selectedFilters);
  };

  const updateFilters = (_filters) => {
    Object.keys(_filters).forEach((itm) => {
      let property = itm;

      let value = _filters[itm];

      switch (property) {
        case "region":
          _filters[property] = value
            .map((el) => {
              return el.value;
            })
            .join(",");

          fetchFilters("nomoi", _filters);

          break;

        case "responsibilities":
          _filters[property] = value
            .map((el) => {
              return el.value;
            })
            .join(",");
          break;

        case "financial_tool":
          _filters[property] = value
            .map((el) => {
              return el.value;
            })
            .join(",");
          break;

        case "nomos":
          _filters[property] = value
            .map((el) => {
              return el.value;
            })
            .join(",");

          fetchFilters("dimoi", _filters);
          break;

        case "dimoi":
          _filters[property] = value
            .map((el) => {
              return el.value;
            })
            .join(",");
          break;

        case "subsection":
          _filters[property] = value
            .map((el) => {
              return el.value;
            })
            .join(",");
          break;

        case "section":
          _filters[property] = value
            .map((el) => {
              return el.value;
            })
            .join(",");
          break;

        case "dates":
          _filters[property] = value
            .map((el) => {
              return el.value;
            })
            .join(",");

          // delete _filters["dates"];
          // delete _filters["from"];
          // delete _filters["to"];

          // try {
          //   if (value.value) {
          //     _filters["from"] = moment(value.value + "-01-01").format(
          //       "YYYY-MM-DD"
          //     );
          //     _filters["to"] = moment(value.value + "-12-31").format(
          //       "YYYY-MM-DD"
          //     );
          //   }
          // } catch (e) {}

          break;

        default:
          _filters[property] = value;
          break;
      }
    });

    props.updateFilters(_filters);
  };

  const getFiltersFromURL = () => {
    if (!URLParamasLoaded) {
      const urlParams = new URLSearchParams(window.location.search);

      let _selectedFilters = {};

      let _filters = {};

      if (urlParams.get("subsections")) {
        let _subSectionsIds = urlParams.get("subsections").split(",");

        let _subSections = [];

        subsections.forEach((el, idx) => {
          if (_subSectionsIds.indexOf(el.value.toString()) >= 0) {
            if (_subSections.indexOf(el.value.toString()) < 0) {
              _subSections.push(el);
            }
          }
        });

        _selectedFilters["subsection"] = _subSections;
        _filters["subsection"] = _subSections;
      }

      if (urlParams.get("regions")) {
        let _subRegionsIds = urlParams.get("regions").split(",");

        let _regions = [];

        regions.forEach((el, idx) => {
          if (_subRegionsIds.indexOf(el.value.toString()) >= 0) {
            if (_regions.indexOf(el.value.toString()) < 0) {
              _regions.push(el);
            }
          }
        });

        _selectedFilters["region"] = _regions;
        _filters["region"] = _regions;
      }

      if (urlParams.get("pe")) {
        let _nomoiIds = urlParams.get("pe").split(",");

        let _nomos = [];

        nomoi.forEach((el, idx) => {
          if (_nomoiIds.indexOf(el.value.toString()) >= 0) {
            if (_nomos.indexOf(el.value.toString()) < 0) {
              _nomos.push(el);
            }
          }
        });

        _selectedFilters["nomos"] = _nomos;
        _filters["nomos"] = _nomos;
      }

      if (urlParams.get("dimoi")) {
        let _dimosIds = urlParams.get("dimoi").split(",");

        let _dimos = [];

        dimoi.forEach((el, idx) => {
          if (_dimosIds.indexOf(el.value.toString()) >= 0) {
            if (_dimos.indexOf(el.value.toString()) < 0) {
              _dimos.push(el);
            }
          }
        });

        _selectedFilters["dimoi"] = _dimos;
        _filters["dimoi"] = _dimos;
      }

      if (urlParams.get("resp")) {
        let _respIds = urlParams.get("resp").split(",");

        let _resp = [];

        responsibilities.forEach((el, idx) => {
          if (_respIds.indexOf(el.value.toString()) >= 0) {
            if (_resp.indexOf(el.value.toString()) < 0) {
              _resp.push(el);
            }
          }
        });

        _selectedFilters["responsibilities"] = _resp;
        _filters["responsibilities"] = _resp;
      }

      if (urlParams.get("dates")) {
        _selectedFilters["dates"] = [
          { value: urlParams.get("dates"), label: urlParams.get("dates") },
        ];
        _filters["dates"] = [
          { value: urlParams.get("dates"), label: urlParams.get("dates") },
        ];
      }

      if (urlParams.get("highlight")) {
        if (urlParams.get("highlight") == "1") {
          _selectedFilters["highlight"] = 1;
          _filters["highlight"] = 1;
        }
      }

      if (urlParams.get("restoration")) {
        if (urlParams.get("restoration") == "1") {
          _selectedFilters["restoration"] = 1;
          _filters["restoration"] = 1;
        }
      }

      if (urlParams.get("labels")) {
        _filters["labels"] = urlParams.get("labels");
      }

      if (urlParams.size > 0) {
        window["gotToSearchResults"]();

        setFilters(_selectedFilters);

        updateFilters(_filters);
      }
    }
  };

  const fetchFilters = (module, _filters) => {
    let filter = "";
    if (module === "nomoi") {
      if (_filters?.region) {
        filter = "&region=".concat(_filters?.region);
      }
    }
    if (module === "dimoi") {
      if (_filters?.region) {
        filter = "&region=".concat(_filters?.region);
      }

      if (_filters?.nomos) {
        filter = "&nomos=".concat(_filters.nomos);
      }
    }

    fetch("/rest/content/data.php?module=".concat(module, filter))
      .then((response) => response.json())
      .then((data) => {
        switch (module) {
          case "status":
            setStatuses([...data.data]);
            break;

          case "sections":
            setSections([...data.data]);
            break;

          case "subsections":
            setSubsections([...data.data]);
            break;

          case "regions":
            setRegions([...data.data]);

            break;

          case "nomoi":
            setNomoi([...data.data]);
            fetchFilters("dimoi", _filters);
            break;

          case "dimoi":
            setDimoi([...data.data]);
            break;

          case "responsibility":
            setResponsibilities([...data.data]);
            break;
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <div className="row">
        <div className="col-10">
          <h2 className="title-display-1">Aναζητήστε έργο στην περιοχή σας</h2>
        </div>
        <div className="col-2 m-auto">
          <div className="d-flex  float-end">
            <Switch
              onChange={(e) => {
                setShowMap(e);
              }}
              checkedIcon={false}
              uncheckedIcon={false}
              height={17}
              width={34}
              checked={showMap}
            />
            <span className="map-switcher-label ms-2">Χάρτης</span>
          </div>
        </div>
      </div>
      <div
        id="map"
        className={"map mt-3 ".concat(showMap ? "" : "d-none")}
        tabindex="0"
      ></div>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className=" p-2">
            <label className="search-label">
              Περιφέρεια{" "}
              {window["translations"]?.tooltips?.search?.perifereies ? (
                <a
                  data-tooltip-id="my-tooltip-1"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search?.perifereies
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-1" />
                </a>
              ) : (
                ""
              )}
            </label>
            {/* <Multiselect
            className="mt-2"
            options={regions}
            selectedValues={[]}
            onSelect={(e) => {
              onChangeFilters("region", e);
            }}
            onRemove={(e) => {
              onChangeFilters("region", e);
            }}
            placeholder="Όλες"
            displayValue="name"
          /> */}
            <div className="filter-label mt-1">
              <ReactMultiSelectCheckboxes
                options={regions}
                className="w-100"
                autoFocus={false}
                loadingMessage="Παρακαλώ περιμένετε..."
                noOptionsMessage={() => {
                  return "Δεν βρέθηκαν επιλογές";
                }}
                placeholderButtonLabel="Όλες"
                value={filters.region}
                placeholder="Αναζήτησε..."
                onChange={(e, a) => {
                  onChangeFilters("region", e);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                  button: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                }}
              />
            </div>
          </div>
          <div className=" p-2">
            <label className="search-label">
              Περιφερειακή Ενότητα{" "}
              {window["translations"]?.tooltips?.search
                ?.perifereiakes_enotites ? (
                <a
                  data-tooltip-id="my-tooltip-3"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search
                      ?.perifereiakes_enotites
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-3" />
                </a>
              ) : (
                ""
              )}
            </label>
            <div className="filter-label mt-1">
              <ReactMultiSelectCheckboxes
                options={nomoi}
                className="w-100"
                autoFocus={false}
                loadingMessage="Παρακαλώ περιμένετε..."
                noOptionsMessage={() => {
                  return "Δεν βρέθηκαν επιλογές";
                }}
                placeholderButtonLabel="Όλες"
                value={filters.nomos}
                placeholder="Αναζήτησε..."
                onChange={(e) => {
                  onChangeFilters("nomos", e);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                  button: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                }}
              />
            </div>
          </div>
          <div className=" p-2">
            <label className="search-label">
              Δήμος{" "}
              {window["translations"]?.tooltips?.search?.dimos ? (
                <a
                  data-tooltip-id="my-tooltip-6"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search?.dimos
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-6" />
                </a>
              ) : (
                ""
              )}
            </label>

            <div className="filter-label mt-1">
              <ReactMultiSelectCheckboxes
                options={dimoi}
                className="w-100"
                autoFocus={false}
                loadingMessage="Παρακαλώ περιμένετε..."
                noOptionsMessage={() => {
                  return "Δεν βρέθηκαν επιλογές";
                }}
                placeholderButtonLabel="Όλοι"
                value={filters.dimoi}
                placeholder="Αναζήτησε..."
                onChange={(e) => {
                  console.log(e);
                  onChangeFilters("dimoi", e);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                  button: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className=" p-2">
            <label className="search-label">
              Κατηγορία Έργου{" "}
              {window["translations"]?.tooltips?.search?.kathgoria_ergou ? (
                <a
                  data-tooltip-id="my-tooltip-1"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search?.kathgoria_ergou
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-1" />
                </a>
              ) : (
                ""
              )}
            </label>
            {/* <Multiselect
            className="mt-2"
            options={subsections}
            selectedValues={[]}
            onSelect={(e) => {
              onChangeFilters("subsection", e);
            }}
            onRemove={(e) => {
              onChangeFilters("subsection", e);
            }}
            placeholder="Όλες"
            displayValue="name"
          /> */}

            <div className="filter-label mt-1">
              <ReactMultiSelectCheckboxes
                options={subsections}
                className="w-100"
                autoFocus={false}
                loadingMessage="Παρακαλώ περιμένετε..."
                noOptionsMessage={() => {
                  return "Δεν βρέθηκαν επιλογές";
                }}
                placeholderButtonLabel="Όλες"
                value={filters.subsection}
                placeholder="Αναζήτησε..."
                onChange={(e) => {
                  onChangeFilters("subsection", e);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                  button: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                }}
              />
            </div>
          </div>
          <div className=" p-2">
            <label className="search-label">
              Φορέας Υλοποίησης{" "}
              {window["translations"]?.tooltips?.search?.foreas_ylopoihshs ? (
                <a
                  data-tooltip-id="my-tooltip-4"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search?.foreas_ylopoihshs
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-4" />
                </a>
              ) : (
                ""
              )}
            </label>

            <div className="filter-label mt-1">
              <ReactMultiSelectCheckboxes
                options={responsibilities}
                className="w-100"
                autoFocus={false}
                loadingMessage="Παρακαλώ περιμένετε..."
                noOptionsMessage={() => {
                  return "Δεν βρέθηκαν επιλογές";
                }}
                placeholderButtonLabel="Όλες"
                value={filters.responsibilities}
                placeholder="Αναζήτησε..."
                onChange={(e) => {
                  onChangeFilters("responsibilities", e);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                  button: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                }}
              />
            </div>
          </div>
          <div className=" p-2">
            <label className="search-label">
              Έτος Ολοκλήρωσης{" "}
              {window["translations"]?.tooltips?.search
                ?.hmeromhnia_ulopoihshs ? (
                <a
                  data-tooltip-id="my-tooltip-7"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search
                      ?.hmeromhnia_ulopoihshs
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-7" />
                </a>
              ) : (
                ""
              )}
            </label>

            <div className="filter-label mt-1">
              <ReactMultiSelectCheckboxes
                options={window["hmeromhnies_ylopoihshs"]}
                className="w-100"
                autoFocus={false}
                loadingMessage="Παρακαλώ περιμένετε..."
                noOptionsMessage={() => {
                  return "Δεν βρέθηκαν επιλογές";
                }}
                placeholderButtonLabel="Όλες"
                value={filters.dates}
                placeholder="Επίλεξε..."
                onChange={(e) => {
                  onChangeFilters("dates", e);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                  button: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                }}
              />
            </div>
            {/*             
            <DatePicker
              value={filters.dates}
              //onChange={setDates}
              months={months}
              onChange={(e) => {
                onChangeFilters("dates", e);
              }}
              className="form-control"
              onlyMonthPicker
              range
              plugins={[<DatePanel months={months} />]}
            /> */}
          </div>
        </div>
        <div className="col-md-4">
          <div className=" p-2">
            <label className="search-label">
              Ειδικά Σχέδια Ανασυγκρότησης{" "}
              {window["translations"]?.tooltips?.search
                ?.sxedia_anasygkrothshs ? (
                <a
                  data-tooltip-id="my-tooltip-2"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search
                      ?.sxedia_anasygkrothshs
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-2" />
                </a>
              ) : (
                ""
              )}
            </label>
            <div className="mt-1">
              <Switch
                onChange={(e) => {
                  onChangeFilters("restoration", e);
                }}
                className=""
                uncheckedIcon={false}
                checkedIcon={false}
                checked={filters.restoration}
              />
            </div>
          </div>
          <div className=" p-2">
            <label className="search-label">
              Εμβληματικά{" "}
              <i className="fas fa-star" style={{ color: "#00aeef" }}></i>{" "}
              {window["translations"]?.tooltips?.search?.embhlhmtika ? (
                <a
                  data-tooltip-id="my-tooltip-5"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search?.embhlhmtika
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-5" />
                </a>
              ) : (
                ""
              )}
            </label>
            <div className="mt-1">
              <Switch
                onChange={(e) => {
                  onChangeFilters("highlight", e);
                }}
                className="mt-1"
                uncheckedIcon={false}
                checkedIcon={false}
                checked={filters.highlight}
              />
            </div>
          </div>
          <div className=" p-2">
            <label className="search-label">
              Χρηματοδοτικό Εργαλείο{" "}
              {window["translations"]?.tooltips?.search?.financial_tools ? (
                <a
                  data-tooltip-id="my-tooltip-7"
                  data-tooltip-content={
                    window["translations"]?.tooltips?.search?.financial_tools
                  }
                >
                  <i className="fas fa-info-circle text-black-50"></i>
                  <Tooltip id="my-tooltip-7" />
                </a>
              ) : (
                ""
              )}
            </label>

            <div className="filter-label mt-1">
              <ReactMultiSelectCheckboxes
                options={window["financial_tools"]}
                className="w-100"
                autoFocus={false}
                loadingMessage="Παρακαλώ περιμένετε..."
                noOptionsMessage={() => {
                  return "Δεν βρέθηκαν επιλογές";
                }}
                placeholderButtonLabel="Όλα"
                value={filters.financial_tool}
                placeholder="Επίλεξε..."
                onChange={(e) => {
                  onChangeFilters("financial_tool", e);
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                  button: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Αναζήτηση βάσει λέξης  "
            value={searchTitle}
            onChange={(e) => {
              setSearchTitle(e.target.value);
            }}
          />
          <button
            className="btn btn-primary"
            type="button"
            id="button-addon2"
            style={{ zIndex: "auto" }}
            onClick={() => {
              onChangeFilters("q", searchTitle);
            }}
          >
            Αναζήτηση
          </button>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6">
          {/* <span className="total">{props.total} έργα</span> */}
        </div>
        <div className="col-md-6 text-end">
          <div className="form-check d-inline-block">
            <input
              className="form-check-input "
              type="checkbox"
              value=""
              id="status1"
              onChange={(e) => {
                onChangeFilters("status1", e.target.checked);
              }}
            />
            <label
              className="form-check-label select-status text-green"
              htmlFor="status1"
            >
              Oλοκληρωμένο
            </label>
          </div>
          <div className="form-check d-inline-block ms-4">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="status2"
              onChange={(e) => {
                onChangeFilters("status2", e.target.checked);
              }}
            />
            <label
              className="form-check-label select-status text-orange"
              htmlFor="status2"
            >
              Σε εξέλιξη
            </label>
          </div>
          <div className="form-check d-inline-block ms-4">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="status3"
              onChange={(e) => {
                onChangeFilters("status3", e.target.checked);
              }}
            />
            <label
              className="form-check-label select-status text-purple"
              htmlFor="status3"
            >
              Υπό σχεδιασμό
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPanel;
