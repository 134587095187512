import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Menu() {
  const [loaded, setLoaded] = useState(false);

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (!loaded) {
      fetchMenu();
    }
  }, [loaded]);

  const fetchMenu = () => {
    fetch("/rest/content/data.php?module=menu&template=main")
      .then((response) => response.json())
      .then((data) => {
        try {
          setMenu(JSON.parse(data.data[0].menu));
          setLoaded(true);
        } catch (e) {}
      })
      .catch((error) => console.error(error));
  };

  function removeAccentsToUpper(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-blue site-menu fixed-top ">
        <div className="container  w-md-50">
          <a href="/" className="navbar-brand ">
            <img src="/images/logo-top.png" className=""></img>
          </a>
          <button
            className="navbar-toggler bg-white me-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              new window["bootstrap"].Collapse("#navbarSupportedContent", {
                toggle: true,
              });
            }}
          >
            <span className="navbar-toggler-icon text-white"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav  ms-auto top-menu">
              {menu.map((el, idx) => {
                if (el.children?.length > 0) {
                  return (
                    <li class="nav-item dropdown ">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {removeAccentsToUpper(el.slogan ? el.slogan : el.title)}
                      </Link>

                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarScrollingDropdown"
                      >
                        {el.children.map((child, cid) => {
                          return (
                            <li>
                              <Link
                                to={
                                  child.module == "categories"
                                    ? "/p/".concat(child.recordid)
                                    : child.link
                                }
                                className="dropdown-item small"
                              >
                                {removeAccentsToUpper(
                                  child.slogan ? child.slogan : child.title
                                )}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li className="nav-item">
                      <Link
                        to={
                          el.module == "categories"
                            ? "/p/".concat(el.recordid)
                            : el.link
                        }
                        className="nav-link"
                      >
                        {removeAccentsToUpper(el.title)}
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
            {/* <ul className="navbar-nav  ms-auto ">
            <li className="nav-item active">
              <a className="nav-link" href="#">
                Home <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Link
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dropdown
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link disabled" href="#">
                Disabled
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Menu;
