import striptags from "striptags";

export function formatBudget(input) {
  if (!isNaN(input)) {
    return new Intl.NumberFormat("en-DE").format(input).concat("€");
  }
  return "";
}

export function truncate(input, length) {
  if (input) {
    if (input.length > length) {
      return input.substring(0, length) + "...";
    }
  }
  return input;
}

export function setMetaTags(title, description, image) {
  try {
    const _title = document.querySelector("title");
    _title.innerText = "Περιφερειακά Σχέδια Ανάπτυξης | ".concat(
      striptags(title)
    );
  } catch (e) {}

  try {
    const _description = document.querySelector("meta[name='description']");
    _description.setAttribute(
      "content",
      striptags(description).split(" ").slice(0, 30).join(" ")
    );
  } catch (e) {}

  try {
    if (image) {
      document
        .querySelectorAll("meta[property=og\\:image]")[0]
        .setAttribute("content", image);
    }
  } catch (e) {}
}

export function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function formatImage(subsection) {
  let images = window["category_images"]
    ? window["category_images"]
    : {
        1: "politismos.jpg",
        2: "pshfiakes_diasyndeseis.jpg",
        3: "aerodromio.jpg",
        4: "paideia.jpg",
        5: "odika_erga.jpg",
        6: "ygeia.jpg",
        7: "perivallon.jpg",
        8: "thalassies_metafores.jpg",
        9: "ydreusi.jpg",
        10: "ardeutika_erga.jpg",
        11: "politiki_prostasia.jpg",
        12: "antiplimirika_erga.jpg",
        13: "agrotiki_odopoiia.jpg",
        14: "athlitismos.jpg",
        15: "veltiosi_kathimerinotitas.jpg",
        16: "anaplaseis.jpg",
        17: "ereuna_kainotomia.jpg",
        18: "apasxolisi.jpg",
        19: "tourismos.jpg",
        20: "epixeirimatikotita.jpg",
        21: "asfaleia.jpg",
        22: "stratigikes_ependyseis.jpg",
        25: "sidirodromika_erga.jpg",
      };

  if (images[subsection]) {
    return "/images/projects/".concat(images[subsection]);
  } else {
    return "";
  }
}
