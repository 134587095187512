import { useEffect, useState } from "react";
import "./styles.css";
import Footer from "./footer";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";

import Menu from "./menu";
import { gotoBox } from "./scripts";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { truncate, formatBudget, formatImage, removeAccents } from "./lib";

function Page() {
  const [loaded, setLoaded] = useState(false);

  const [seeMoreProjects, setSeeMoreProjects] = useState(null);

  const [page, setPage] = useState({});

  const params = useParams();

  useEffect(() => {
    fetchPage(params.id);

    window.scrollTo(0, 0);
  }, [loaded, params.id]);

  const fetchPage = (id) => {
    fetch("/rest/content/data.php?module=categories&template=L&recordid=" + id)
      .then((response) => response.json())
      .then((data) => {
        try {
          setPage(data.data[0]);
          setSeeMoreProjects(null);
          setLoaded(true);
          setTimeout(gotoBox, 500);
        } catch (e) {}
      })
      .catch((error) => console.error(error));
  };

  const projectCard = (project) => {
    let badgeBgColor = "";
    let statusTooltipProperty = "";
    switch (project.status?.toString()) {
      case "3":
        badgeBgColor = "#538249";
        statusTooltipProperty = "complete";
        break;

      case "2":
        badgeBgColor = "#AD6330";
        statusTooltipProperty = "pending";
        break;

      case "1":
        badgeBgColor = "#694A9C";
        statusTooltipProperty = "design";
        break;
    }

    let regions = project.region.split(",");

    let regionsContent = [];
    let moreRegions = [];

    regions.forEach((r, i) => {
      if (i <= 1) {
        if (i == 0) {
          regionsContent.push(
            <span className="project-badge p-2 pt-1 pb-1">
              {removeAccents(r)}
            </span>
          );
        } else {
          regionsContent.push(
            <span className="project-badge p-2 pt-1 pb-1   d-inline-block">
              {removeAccents(r)}
            </span>
          );
        }
      } else {
        moreRegions.push(
          <span className="project-badge-more p-2 pt-1 pb-1">
            {removeAccents(r)}
          </span>
        );
      }
    });

    if (moreRegions.length > 0) {
      regionsContent.push(
        <span
          className="project-badge p-2 pt-1 pb-1 d-inline-block"
          data-bs-toggle="collapse"
          data-bs-target={"#collapse-".concat(project.project_id)}
        >
          +{moreRegions.length}
        </span>
      );
    }

    return (
      <div
        className={"project-card h-100 position-relative ".concat(
          project.highlight == "1" ? "highlighted" : ""
        )}
      >
        <div
          className="project-imgbox"
          style={{
            backgroundImage: "url(".concat(
              project.image ? project.image : formatImage(project.subsectionid),
              ")"
            ),
          }}
        >
          <div className="project-imgbox-filter"></div>
          {project.highlight ? (
            window["translations"]?.tooltips?.project?.highlighted ? (
              <div className="project-star">
                <a
                  data-tooltip-id={"my-tooltip-highlight-" + project.project_id}
                  data-tooltip-content={
                    window["translations"]?.tooltips?.project?.highlighted
                  }
                >
                  <i
                    className="fas fa-star shadow-sm"
                    style={{ color: "#00aeef" }}
                  ></i>{" "}
                  <Tooltip id={"my-tooltip-highlight-" + project.project_id} />
                </a>
              </div>
            ) : (
              <div className="project-star">
                <i
                  className="fas fa-star shadow-sm"
                  style={{ color: "#00aeef" }}
                ></i>
              </div>
            )
          ) : (
            ""
          )}

          {project.restoration ? (
            window["translations"]?.tooltips?.project?.sxedio_anasygkrothshs ? (
              <div className="project-restoration">
                <a
                  data-tooltip-id={
                    "my-tooltip-restoration-" + project.project_id
                  }
                  data-tooltip-content={
                    window["translations"]?.tooltips?.project
                      ?.sxedio_anasygkrothshs
                  }
                >
                  <img src="/images/wip.svg"></img>
                  <Tooltip
                    id={"my-tooltip-restoration-" + project.project_id}
                  />
                </a>
              </div>
            ) : (
              <div className="project-restoration">
                <img src="/images/wip.svg"></img>
              </div>
            )
          ) : (
            ""
          )}

          <a
            className="project-status"
            style={{ backgroundColor: badgeBgColor }}
            data-tooltip-id={"my-tooltip-status-" + project.project_id}
            data-tooltip-content={
              window["translations"]?.tooltips?.status[statusTooltipProperty]
            }
          >
            <Tooltip id={"my-tooltip-status-" + project.project_id} />
          </a>
          <div className="project-regions">{regionsContent}</div>
        </div>
        <div className="project-body p-2 bg-white">
          <Link to={"/".concat(project.project_id)}>
            <span className="title-display-3 d-block">
              <strong>{project.subsection}</strong>
            </span>
            <span className="title-display-4 d-block ">{project.section}</span>

            <h3 className="title-display-2 d-block mt-2 mb-2">
              {truncate(project.title, 80)}
            </h3>
          </Link>
        </div>
        <div className="project-footer d-flex p-2  pe-3">
          <span className="title-display-5 flex-fill ">
            {project.budget && formatBudget(project.budget) ? (
              "Προϋπολ."
            ) : (
              <div style={{ height: "21px" }}></div>
            )}
          </span>
          <span className="title-display-5">
            <strong>
              {project.budget ? formatBudget(project.budget) : ""}
            </strong>
          </span>
        </div>
        <div
          className="projects-more-regions collapse"
          id={"collapse-".concat(project.project_id)}
        >
          {moreRegions}
        </div>
      </div>
    );
  };

  const SeeMore = (properties) => {
    if (seeMoreProjects.length > 0) {
      return (
        <section
          className="container  p-3 ps-4 pe-4 rounded"
          style={{ backgroundColor: "#eaeaea" }}
          id="see_more_results"
        >
          <h2 class="title-display-2 mt-3 text-center">
            Ενδεικτικά έργα για την {page.title ? parse(page.title) : ""}
          </h2>
          <div className="row mt-3">
            {seeMoreProjects?.map((project) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 p-2">
                  {projectCard(project)}
                </div>
              );
            })}
          </div>
          <div className="mt-3 text-end">
            <span
              onClick={() => {
                window.location.replace("/?regions=" + properties?.regionId);
              }}
              style={{ cursor: "pointer" }}
            >
              Δείτε τα όλα <i className="fas fa-external-link-alt"></i>
            </span>
          </div>
        </section>
      );
    }

    return "";
  };

  const fetchSeeMoreProjects = (regionId) => {
    let url = "/rest/content/data.php?module=projects&limit=4&region=".concat(
      regionId
    );

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setSeeMoreProjects(data.data);
      })
      .catch((error) => console.error(error));
  };

  const formatContent = (content) => {
    if (content) {
      var mySubString = content.substring(
        content.indexOf("{{") + 2,
        content.lastIndexOf("}}")
      );

      if (mySubString) {
        if (mySubString.indexOf("region_projects_") >= 0) {
          let _content = content?.split("{{".concat(mySubString, "}}"));

          let regionId = mySubString.split("_")[2];

          if (!seeMoreProjects) {
            fetchSeeMoreProjects(regionId);
            return parse(content.replace("{{" + mySubString + "}}", ""));
          } else {
            return [
              parse(_content[0]),
              <SeeMore regionId={regionId}></SeeMore>,
              parse(_content[1]),
            ];
          }
        }
        return parse(content);
      }

      return parse(content);
    }
    return null;
  };

  return (
    <>
      <Menu></Menu>
      <div
        className="container-fluid hero-banner ps-2 pe-2 pt-5 box-shadow"
        style={{
          backgroundImage: "url(/images/hero-bg.jpg)",
          backgroundSize: "cover",
          backgroundPositionY: "center",
          minHeight: "250px",
        }}
      >
        <div className="container  w-md-50 mt-5 ps-2 pe-2 ">
          <div
            className="text-white detail-site-title"
            style={{ marginTop: "80px" }}
          >
            <h1>{page?.title ? parse(page.title) : ""}</h1>
          </div>
        </div>
      </div>
      <section className="container w-md-50 mt-5 ps-2 pe-2 context">
        <div className="content-text content-page">
          {formatContent(page?.content)}
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Page;
