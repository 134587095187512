import { useEffect, useState } from "react";
import "./styles.css";
import Footer from "./footer";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";

import Map from "ol/Map";
import OSM from "ol/source/OSM.js";
import TileLayer from "ol/layer/Tile.js";
import View from "ol/View.js";
import GeoJSON from "ol/format/GeoJSON.js";
import VectorSource from "ol/source/Vector.js";
import { Vector as VectorLayer } from "ol/layer.js";
import "ol/ol.css";
import Menu from "./menu";
import { formatImage, setMetaTags } from "./lib";

function ProjectDetails() {
  const [loaded, setLoaded] = useState(false);

  const [project, setProject] = useState({});

  useEffect(() => {
    if (!loaded) {
      fetchProject();

      window["map"] = new Map({
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        target: "map",
        view: new View({
          center: [2642432.187, 4601208.467],
          zoom: 6,
        }),
      });

      window.scrollTo(0, 0);
    } else {
      if (project.regionId && !project.municipalityId && !project.nomosId) {
        let perifereies = new VectorLayer({
          source: new VectorSource({
            format: new GeoJSON(),
            url: function (extent) {
              return (
                "//geodata.gov.gr/geoserver/ows?service=WFS&" +
                "version=2.0.0&request=GetFeature&typeNames=geodata.gov.gr:d7f50467-e5ef-49ac-a7ce-15df3e2ed738&" +
                "outputFormat=json&srsname=EPSG:3857&" +
                "featureId=" +
                project.regionId
              );
            },
          }),
          style: {
            "stroke-width": 0.75,
            "stroke-color": "white",
            "fill-color": "rgba(42,109,134,0.5)",
          },
        });
        window["map"].addLayer(perifereies);

        perifereies.getSource().on("featuresloadend", function (evt) {
          window["map"].getView().fit(perifereies.getSource().getExtent());
        });
      }

      if (project.nomosId && !project.municipalityId) {
        let nomous = new VectorLayer({
          source: new VectorSource({
            format: new GeoJSON(),
            url: function (extent) {
              return (
                "//geodata.gov.gr/geoserver/ows?service=WFS&" +
                "version=2.0.0&request=GetFeature&typeNames=geodata.gov.gr:e81089cb-f48c-4c5b-ac77-aa3028fe31fb&" +
                "outputFormat=json&srsname=EPSG:3857&" +
                "featureId=" +
                project.nomosId
              );
            },
          }),
          style: {
            "stroke-width": 0.75,
            "stroke-color": "white",
            "fill-color": "rgba(42,109,134,0.5)",
          },
        });
        window["map"].addLayer(nomous);

        if (!project.municipalityId) {
          nomous.getSource().on("featuresloadend", function (evt) {
            window["map"].getView().fit(nomous.getSource().getExtent());
          });
        }
      }

      if (project.municipalityId) {
        let dimoi = new VectorLayer({
          source: new VectorSource({
            format: new GeoJSON(),
            url: function (extent) {
              return (
                "//geodata.gov.gr/geoserver/ows?service=WFS&" +
                "version=2.0.0&request=GetFeature&typeNames=geodata.gov.gr:c7b5978b-aca9-4d74-b8a5-d3a48d02f6d0&" +
                "outputFormat=json&srsname=EPSG:3857&" +
                "featureId=" +
                project.municipalityId
              );
            },
          }),
          style: {
            "stroke-width": 0.75,
            "stroke-color": "white",
            "fill-color": "rgba(42,109,134,0.5)",
          },
        });
        window["map"].addLayer(dimoi);

        dimoi.getSource().on("featuresloadend", function (evt) {
          window["map"].getView().fit(dimoi.getSource().getExtent());
        });
      }
    }
  }, [loaded]);

  function getQueryPath(index, url = window.location.pathname) {
    let splitUrl = url.split("/");

    return splitUrl[index];
  }

  const fetchProject = () => {
    fetch(
      "/rest/content/data.php?module=project&limit=12&id=".concat(
        getQueryPath(1)
      )
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.data[0]) {
          setProject(data.data[0]);

          setMetaTags(
            data.data[0].title,
            data.data[0].description,

            data.data[0].image
              ? data.data[0].image
              : formatImage(data.data[0].subsectionid)
          );
        }

        setLoaded(true);
      })
      .catch((error) => console.error(error));
  };

  function formatBudget(input) {
    return new Intl.NumberFormat("en-DE").format(input).concat("€");
  }

  function formatTags(input) {
    if (input) {
      return input
        .replaceAll(" ; ", ",")
        .replaceAll(",", ", ")
        .replaceAll("  ", " ");
    }

    return "-";
  }

  const statusImage = (project) => {
    let output = null;

    switch (project.status?.toString()) {
      case "1":
        output = (
          <img src="/images/status1.png" style={{ maxHeight: "20rem" }}></img>
        );

        if (window["project_with_status_1b"]) {
          if (window["project_with_status_1b"].indexOf(project.project_id)) {
            output = (
              <img
                src="/images/status1b.png"
                style={{ maxHeight: "20rem" }}
              ></img>
            );
          }
        }
        break;

      case "2":
        if (project.substatus == "1") {
          output = (
            <img
              src="/images/status21.png"
              style={{ maxHeight: "20rem" }}
            ></img>
          );
        } else if (project.substatus == "2") {
          output = (
            <img
              src="/images/status22.png"
              style={{ maxHeight: "20rem" }}
            ></img>
          );
        } else if (project.substatus == "3") {
          output = (
            <img
              src="/images/status23.png"
              style={{ maxHeight: "20rem" }}
            ></img>
          );
        } else {
          output = (
            <img src="/images/status2.png" style={{ maxHeight: "20rem" }}></img>
          );
        }
        break;

      case "3":
        output = (
          <img src="/images/status3.png" style={{ maxHeight: "20rem" }}></img>
        );
        break;
    }

    return output;
  };

  return (
    <>
      <Menu></Menu>
      <div
        className="container-fluid hero-banner ps-2 pe-2 pt-5 box-shadow"
        // style={{
        //   backgroundImage: "url(/images/hero-bg.jpg)",
        //   backgroundSize: "cover",
        // }}

        style={{
          backgroundImage: "url(".concat(
            project.image ? project.image : formatImage(project.subsectionid),
            ")"
          ),
          backgroundSize: "cover",
          backgroundPositionY: "center",
        }}
      >
        <div
          className="container w-md-50 ps-2 pe-2 pb-5"
          style={{ marginTop: "40px" }}
        >
          <div className="row">
            <div className="col-md-8  ">
              <Link className="detail-back-to-list" to="/">
                <i
                  className="fas fa-arrow-left me-2"
                  style={{ color: "#00AEEF" }}
                ></i>
                Πίσω στη λίστα
              </Link>
              <div className="text-white detail-site-title">
                Περιφερειακά Σχέδια Ανάπτυξης
              </div>
              <h1 className="detail-title">{project.title}</h1>
            </div>
            <div className="col-md-4">
              <div className="detail-card p-5">
                {project.budget && project.budget !== "-" ? (
                  <>
                    <label className="card-label">Προϋπολογισμός έργου</label>
                    <div className="card-text mt-3">
                      {formatBudget(project.budget)}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {project.dateofcompletion != "0000-00-00" ? (
                  <>
                    <label className="card-label mt-4">
                      {project.status?.toString() == "3"
                        ? "Ολοκλήρωση"
                        : "Εκτιμώμενη ολοκλήρωση"}
                    </label>
                    <div className="card-text mt-3">
                      {project?.dateofcompletion?.substr(
                        project.dateofcompletion.length - 6
                      ) === "-12-12"
                        ? moment(project.dateofcompletion).format("YYYY")
                        : moment(project.dateofcompletion).format("MM/YYYY")}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {/* <label className="card-label mt-4">Κατασταση</label> */}
                <div className="mt-5">
                  <span className="card-badge  ps-3 pe-3 pt-2 pb-2 text-white">
                    {project.statustext}
                  </span>
                </div>
                <div className="text-white">
                  {" "}
                  {project.substatus && project.status == "2" ? (
                    <>
                      <br></br>
                      {window["substatus"][project.substatus]}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container w-md-50 mt-5 ps-2 pe-2 context">
        <div className="content-text">
          {project.description ? parse(project.description) : ""}

          {project.progress ? (
            <>
              <h3 className="detail-text-tags mt-5">Πρόοδος υλοποίησης</h3>
              <p>{parse(project.progress)}</p>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="text-center mt-5">{statusImage(project)}</div>
        <div className="mt-5 text-center">
          {window["user_instructions_link"] ? (
            <span>{parse(window["user_instructions_link"])}</span>
          ) : null}
        </div>
        <div className="row mt-5">
          <div className="col-md-4">
            {project.labels ? (
              <div className="detail-text-tags mb-3">
                {formatTags(project.labels)}
              </div>
            ) : (
              ""
            )}
            <label className="detail-text-label">Kατηγορία έργου</label>
            <div className="detail-text-tags">
              {formatTags(project.subsection)}
            </div>
            <label className="detail-text-label mt-3">Άξονας</label>
            <div className="detail-text-tags">
              {formatTags(project.section)}
            </div>
            <label className="detail-text-label mt-3">Περιφέρεια</label>
            <div className="detail-text-tags">{formatTags(project.region)}</div>
            {project.nomos ? (
              <>
                <label className="detail-text-label mt-3">
                  Περιφερειακή ενότητα
                </label>
                <div className="detail-text-tags">
                  {formatTags(project.nomos)}
                </div>
              </>
            ) : (
              ""
            )}
            {project.municipality ? (
              <>
                <label className="detail-text-label mt-3">Δήμος</label>
                <div className="detail-text-tags">
                  {formatTags(project.municipality)}
                </div>
              </>
            ) : (
              ""
            )}
            {project.responsibility ? (
              <>
                {" "}
                <label className="detail-text-label mt-3">
                  Φορέας Υλοποίησης
                </label>
                <div className="detail-text-tags">
                  {formatTags(project.responsibility)}
                </div>
              </>
            ) : (
              ""
            )}
            {project.financialtool ? (
              <>
                <label className="detail-text-label mt-3">
                  Χρηματοδοτικό εργαλείο
                </label>
                <div className="detail-text-tags">
                  {formatTags(project.financialtool)}
                </div>
              </>
            ) : (
              ""
            )}
            <label className="mt-5">
              <small>Τελευταία ενημέρωση</small>
            </label>
            <div className="">
              <small>{window["projects_last_update"](project)}</small>
            </div>
          </div>
          <div className="col-md-8">
            <div id="map" className={"map mt-3 "} tabindex="0"></div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default ProjectDetails;
