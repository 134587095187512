import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [loaded, setLoaded] = useState(false);

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (!loaded) {
      fetchMenu();
    }
  }, [loaded]);

  const fetchMenu = () => {
    fetch("/rest/content/data.php?module=menu&template=bottom")
      .then((response) => response.json())
      .then((data) => {
        try {
          setMenu(JSON.parse(data.data[0].menu));
          setLoaded(true);
        } catch (e) {}
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <footer className="">
        <div className="container  ">
          <div className="text-center w-100 text-white">
            <img src="/images/logo-top.png" className="mt-5"></img>
          </div>
          <div className=" text-white mt-5 text-center pb-3">
            {menu.map((itm, idx) => {
              if (idx < menu.length - 1) {
                return (
                  <>
                    <span className="small ">
                      <Link
                        to={
                          itm.module == "categories"
                            ? "/p/".concat(itm.recordid)
                            : itm.link
                        }
                        className="text-white"
                      >
                        {itm.title}
                      </Link>
                    </span>
                    {" | "}
                  </>
                );
              } else {
                return (
                  <span className="small ">
                    <Link
                      to={
                        itm.module == "categories"
                          ? "/p/".concat(itm.recordid)
                          : itm.link
                      }
                      className="text-white"
                    >
                      {itm.title}
                    </Link>
                  </span>
                );
              }
            })}
          </div>
        </div>
      </footer>
      <div className="bottom-menu">
        <div className="container  w-md-50 ps-2 pe-2">
          <div className="text-end">
            <span
              className="small d-inline-block"
              style={{ fontSize: "10px", color: "#e3e3e3" }}
            >
              Υλοποίηση από{" "}
            </span>
            <a href="https://www.cognity.gr" target="new">
              <img
                src="/images/cognity.png"
                className="d-inline-block"
                style={{ height: "30px" }}
              ></img>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
