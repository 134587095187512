export function gotoBox() {
  let boxes = document.getElementsByClassName("page-box");

  if (boxes) {
    Array.from(boxes).forEach((el) => {
      if (!el.getAttribute("onclick")) {
        if (el.id) {
          let box = el.id.replace("goto_", "#");

          el.addEventListener("click", function () {
            window["goTo"](box);
          });
        }
      }
    });
  }
}
